import { useEffect, useState } from 'react';
import Base from '../utils/base'

export default function ModalTracking({title, tracking_type_selected, tracking_id_selected}){
    var base = new Base()

    const [modal_menu_arr] = useState([
        {title : 'Single Product & Multiple Quantity', icon : 'bi bi-exclamation-diamond-fill', nav : '/tracking/add-record?type=single'},
        {title : 'Multiple Product & Single Quantity', icon : 'bi bi-exclamation-diamond-fill', nav : '/tracking/add-record?type=multiple'},
    ])

    async function navigatePage(index){
        await localStorage.setItem('tracking_process_id', tracking_id_selected)
        window.location.href = modal_menu_arr[index].nav + '&tracking-type=' + tracking_type_selected
    }

    return(
        <>
        <div className="modal fade" id="modalTracking" tabIndex="-1" aria-labelledby="modalTrackingLabel" aria-hidden="true">
            <div className="modal-dialog p-4 pt-5 mt-5">
                <div className="modal-content rounded border-0 shadow-sm">
                    <div className="modal-body rounded">
                        <div className={'row'}>
                            <div className='col-12'>
                                <p className='m-0 font-weight-bold' style={{fontSize : '1.25rem'}}>Pick Scanning Mode</p>
                            </div>
                            <div className='col-12 mt-4'>
                                <div className='row'>
                                    {
                                        modal_menu_arr.map((data, index)=>(
                                            <div className='col-6' key={index}>
                                                <div className='p-3 d-flex align-items-center justify-content-center shadow-sm h-100' style={{border : '1px solid #eaeaea', borderBottom : '4px solid #347AB5', cursor : 'pointer'}} onClick={()=>navigatePage(index)}>
                                                    <div>
                                                        <h4 className='m-0 text-center' style={{color : '#9FA2B4'}}><i className={data.icon}></i></h4>
                                                        <p className='m-0 text-center mt-2' style={{fontSize : '.75rem', lineHeight : '1rem'}}>{data.title}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}