import React, { useEffect, useState } from 'react';
import Base from '../../utils/base';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import SingleScan from '../../components/singleScan';
import MultipleScan from '../../components/multipleScan';
import PageHeader from '../../components/pageHeader';
import PaletteList from '../../components/paletteList';

export default function WarehouseCheckInput(){
    var base = new Base()

    function useQuery(){
        const {search} = useLocation()
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery()

    const [action_btn_arr, set_action_btn_arr] = useState([
        {title : 'Reinput Palette', icon : 'bi bi-upc-scan', btn_type : 'btn-outline-primary', type : 'retype_palette'},
        {title : 'Confirm', icon : '', btn_type : 'btn-primary', type : 'confirm'},
    ])

    const [palette_qr_result, set_palette_qr_result] = useState({PKC_CID : '', PDB_CDESCRIPTION : '', PDB_CBARCODE : '', palette : null})
    const [is_disabled, set_is_disabled] = useState(false)
    const [palette_data, set_palette_data] = useState({PAL_CID : '', PKC_CID : ''})
    const [arr_product, set_arr_product] = useState([])

    const [arr_palette, set_arr_palette] = useState([])


    useEffect(async ()=>{
        var token = await localStorage.getItem('token')
        if(token == null){
            window.location.href = '/auth/login'
        }

        var data_palette = await localStorage.getItem('packing_input_palette')
        data_palette = JSON.parse(data_palette)
        if(data_palette != null){
            for(var x in data_palette){
                data_palette[x].quantity = 1
            }

            var sort = data_palette.sort((a,b)=>a.PKC_CCODE > b.PKC_CCODE ? 1 : -1)
            set_arr_palette(sort)
        }

        // if(data_palette != null){
        //     set_palette_data(data_palette)
        //     var data_product = []
        //     var item = data_palette.item
        //     for(var x in item){
        //         item[x].production_barcode.quantity = item[x].P2I_NQTY
        //         data_product.push(item[x].production_barcode)
        //     }
        //     set_arr_product(data_product)
        // }
        // else{
        //     window.history.back()
        // }
    }, [])

    async function actionBtn(type){
        if(type === 'retype_palette'){
            await localStorage.removeItem('packing_input_palette')
            window.location.replace('/packaging/input-id?type=' + query.get('type') + '&input_type=palette')
        }
        else if(type === 'confirm'){

            if(navigator.onLine){
                // base.$('#preloader_modal').modal('show')
                // set_is_disabled(true)

                var palette_arr = []

                for(var x in arr_palette){
                    palette_arr.push({palette : {id : arr_palette[x].PAL_CID}})
                }

                var data = {
                    type : query.get('type'),
                    arr_palette : palette_arr
                }
                
                var url = '/warehouse'
                if(query.get('is_temp') != null){
                    if(query.get('is_temp') == 1){
                        url += '/temp'
                    }
                }

                var method = 'post'

                var response = await base.request(url, method, data)
                if(response != null){
                    if(response.status == 'success'){
                        await localStorage.removeItem('packing_input_palette')
                        window.location.replace('/success')
                    }
                    else{
                        errorPost()
                    }
                }
                else{
                    errorPost()
                }
            }
            else{
                errorPost()
            }
        }
    }

    function errorPost(){
        base.hidePreloader()
        base.$('#error_modal').modal('show')
        set_is_disabled(false)
    }

    async function addPalette(){
        var palette_arr = await localStorage.getItem('packing_input_palette')
        if(palette_arr == null){
            await localStorage.setItem('packing_input_palette', JSON.stringify(arr_palette))
        }

        var url = '/warehouse/input-id?type=' + query.get('type')
        if(query.get('is_temp') != null){
            if(query.get('is_temp') == 1){
                url += '&is_temp=1'
            }
        }
        window.location.href = url
    }

    async function viewPalette(type='', index){
        window.location.href = '/inventory/detail-palette?id=' + arr_palette[index].PAL_CID
    }

    async function backToPage(){
        if(window.history.length > 2){
            await localStorage.removeItem('packing_input_palette')
            await localStorage.removeItem('production_line_id')
            window.history.back()
        }
        else{
            await localStorage.removeItem('packing_input_palette')
            await localStorage.removeItem('production_line_id')
            window.location.replace('/')
        }
    }


    return(
        <>
            <div className='page_wrap'>
                <div className='row'>

                    <div className='col-12'>
                    <PageHeader title={(query.get('type') === 'back_to_packing' ? 'Back to Packing' : query.get('type') === 'warehouse' ? 'Collect to Warehouse' : '')} icon={(query.get('type') === 'remove' ? 'bi bi-dash-circle-fill' : null)} style={{fontSize : '1.25rem', fontWeight : '600'}} is_back_btn={true} customBack={true} backToPage={()=>backToPage()} />
                    </div>

                    <div className='col-12 mt-3'>
                        <div className='row m-0'>
                            <div className='col-12 mt-3'>
                                <div className='row'>
                                    {/* <div className='col-12 px-0 d-flex align-items-center'>
                                        <p className='m-0'>Palette ID</p>
                                    </div> */}
                                    {/* <div className='col-12'>
                                        <div className='row'>
                                            <SingleScan type={'palette'} data={palette_data} />
                                        </div>
                                    </div> */}

                                    <div className='col-12 p-0 mt-3'>
                                        <div className='row'>
                                            <div className='col d-flex align-items-center'>
                                                <p className='m-0'>Palette</p>
                                            </div>
                                            <div className='col d-flex align-items-center justify-content-end'>
                                                <button className='btn btn-outline-primary rounded' onClick={()=>addPalette()}><i class="bi bi-plus-circle-fill"></i> Add Palette</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <PaletteList data_arr={arr_palette} viewFrom={'history'} actionBtn={(type, index)=>viewPalette(type, index)} process={'warehouse'} />
                                        {/* <MultipleScan type={'palette'} data_arr={arr_palette} /> */}
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 p-0 mt-3'>
                                <div className='py-2' style={{backgroundColor : '#F5F5F5'}}>
                                    <p className='m-0 text-center font-weight-bold' style={{color : '#777777'}}>Total {arr_palette.length} Product</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            {
                query.get('type') !== 'view' &&
                <div className='position-fixed w-100 py-2 shadow-lg' style={{bottom : 0, backgroundColor : '#FAFAFA'}}>
                    <div className='row m-0'>
                        <div className='col-12'>
                        {
                            action_btn_arr.map((data, index)=>(
                                <>
                                    <button className={'btn btn-lg w-100 rounded ' + (data.btn_type) + (index != 0 ? ' mt-3' : '')} onClick={()=>actionBtn(data.type)} key={index} disabled={is_disabled}>
                                        {
                                            data.icon !== '' &&
                                            <i className={data.icon + ' mr-2'}></i>    
                                        }
                                        {data.title}
                                    </button>
                                </>
                            ))
                        }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}