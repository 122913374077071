import React, { useEffect, useState } from 'react';
import Base from '../../utils/base';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import SingleScan from '../../components/singleScan';
import MultipleScan from '../../components/multipleScan';
import FormInput from '../../components/FormInput';
import PageHeader from '../../components/pageHeader';

export default function InventoryDetailPalette() {
    var base = new Base()

    const [palette_data, set_palette_data] = useState({ PAL_CID: '', item: [], arr_item: [] })
    const [table_header_arr] = useState(['Product ID', 'Product', 'No Barcode', 'Qty'])

    function useQuery() {
        const { search } = useLocation()
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery()

    useEffect(async () => {
        // await localStorage.clear()
        var token = await localStorage.getItem('token')
        if (token == null) {
            window.location.href = '/auth/login'
        }

        if (navigator.onLine) {
            if (query.get('id') != null) {
                get_data()
            }
            else {
                var data = await localStorage.getItem('product_data')
                data = JSON.parse(data)
                var item_arr = data.arr_item
                for(var x in item_arr){
                    if(item_arr[x].P2I_CID != null){
                        data.arr_item = data.arr_item.sort((a,b)=>a.P2I_CID > b.P2I_CID ? 1 : -1)
                    }
                    else{
                        data.arr_item = data.arr_item.sort((a,b)=>a.PDB_CID > b.PDB_CID ? 1 : -1)
                    }
                }
                
                set_palette_data(data)
            }
        }
        else {
            base.$('#error_modal').modal('show')
        }
    }, [])

    async function get_data() {
        base.$('#preloader_modal').modal('show')

        var url = '/palette?id=' + query.get('id')
        var response = await base.request(url)
        if (response != null) {
            if (response.status == 'success') {
                var data = response.data
                var item = data.arr_item
                for(var x in item){
                    if(item[x].P2I_CID != null){
                        data.arr_item = data.arr_item.sort((a,b)=>a.P2I_CID > b.P2I_CID ? 1 : -1)
                    }
                    else{
                        data.arr_item = data.arr_item.sort((a,b)=>a.PDB_CID > b.PDB_CID ? 1 : -1)
                    }
                }
                set_palette_data(data)
                base.hidePreloader()
            }
            else {
                base.hidePreloader()
                base.$('#error_modal').modal('show')
            }
        }
        else {
            base.hidePreloader()
            base.$('#error_modal').modal('show')
        }
    }

    return (
        <>
            <div className='page_wrap'>
                <div className='row'>

                    <div className='col-12'>
                        <PageHeader title={'Detail Palette'} style={{ fontSize: '1.25rem', fontWeight: '600' }} is_back_btn={true} />
                    </div>

                    <div className='col-12 mt-5'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col-4 d-flex align-items-center'>
                                        <p className='m-0'>Palette ID</p>
                                    </div>
                                    <div className='col-8'>
                                        <FormInput
                                            type={'text'}
                                            value={palette_data.PAL_CID}
                                            readOnly
                                            changeInput={(value) => console.log(value)} />
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 mt-3'>
                                <div className='row m-0'>
                                    <div className='col-12 mt-3'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='row'>
                                                    <div className='col-12 p-0'>
                                                        <div class="table-responsive">
                                                            <table class="table">
                                                                <thead>
                                                                    <tr>
                                                                        {
                                                                            table_header_arr.map((data, index) => (
                                                                                <th style={{ fontSize: '.75rem' }} key={index}>{data}</th>
                                                                            ))
                                                                        }
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        palette_data.arr_item.map((data, index) => (
                                                                            <tr key={index}>
                                                                                <td style={{ fontSize: '.75rem' }}>{data.P2I_CID != null ? data.P2I_CID : data.production_barcode.PDB_CID}</td>
                                                                                <td style={{ fontSize: '.75rem' }}>{(data.production_barcode != null ? data.production_barcode.PDB_CDESCRIPTION : '')}</td>
                                                                                <td style={{ fontSize: '.75rem' }}>{(data.production_barcode != null ? data.production_barcode.PDB_CBARCODE : '')}</td>
                                                                                <td style={{ fontSize: '.75rem' }}>{data.P2I_NQTY}</td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div className='col-12 p-0 mt-3'>
                                                        <div className='py-2' style={{ backgroundColor: '#F5F5F5' }}>
                                                            <p className='m-0 text-center font-weight-bold' style={{ color: '#777777' }}>Total {palette_data.arr_item.length} Product</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}