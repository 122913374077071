import { useEffect, useState } from 'react';
import Base from '../../utils/base';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ModalTracking from '../../components/TrackingModal';

export default function HomeIndex(){
    var base = new Base()

    const [menu_arr, set_menu_arr] = useState([])

    const [tracking_type_selected, set_tracking_type_selected] = useState('')
    const [tracking_id_selected, set_tracking_id_selected] = useState('')
    const [default_input, set_default_input] = useState('')

    useEffect(async ()=>{
        // await localStorage.clear()
        var token = await localStorage.getItem('token')
        if(token == null){
            window.location.href = '/auth/login'
        }
        else{
            if(navigator.onLine){
                var get_default_input = await base.get_default_input()
                set_default_input(get_default_input)
            }
            else{
                base.$('#error_modal').modal('show')
            }
        }

        await localStorage.removeItem('tracking_process_id')
        await localStorage.removeItem('qr_result_temp')
        await localStorage.removeItem('temp_status_selected')
        await localStorage.removeItem('production_line_id')
        await localStorage.removeItem('packing_input_palette')
        await localStorage.removeItem('packing_input_product')
        await localStorage.removeItem('qr_mutation_stock')

    }, [])

    useEffect(async ()=>{
        if(default_input !== ''){
            await get_data()
        }
    }, [default_input])

    async function get_data(e){
        var url = '/production/line'
        var response = await base.request(url)
        if(response != null){
            if(response.status == 'success'){
                var data = response.data
                for(var x in data){
                    data[x].title = data[x].id

                    var title_split = data[x].id.split('_')
                    if(title_split.length > 1){
                        data[x].title = ''
                        for(var y in title_split){
                            data[x].title += title_split[y] + ' '
                        }
                    }

                    var sub_menu_arr = data[x].arr
                    for(var y in sub_menu_arr){
                        sub_menu_arr[y].title = sub_menu_arr[y].PDL_CNAME
                        sub_menu_arr[y].icon = 'bi bi-exclamation-diamond-fill'
                        sub_menu_arr[y].is_modal = (data[x].id === 'tracking' ? true : false)
                        sub_menu_arr[y].type = sub_menu_arr[y].PDL_CNAME
                        
                        var nav = ''
                        
                        if(data[x].id === 'tracking'){
                            nav = '#modalTracking'
                            sub_menu_arr[y].nav = nav
                        }

                        
                        if(data[x].id !== 'tracking'){
                            var input_type = ''
                            if(default_input === 'manual_input'){
                                input_type = '/input-id'
                            }
                            else if(default_input === 'camera_input'){
                                input_type = '/scan-barcode'
                            }
                            sub_menu_arr[y].nav = '/' + data[x].id + input_type + '?type=' + sub_menu_arr[y].PDL_CID + '&input_type=palette'
                            if(data[x].id.includes('temp')){
                                sub_menu_arr[y].nav += '&is_temp=1'
                            }
                        }
                    }
                }
                set_menu_arr(data)
            }
            else{
                setTimeout(() => {
                    base.$('#error_modal').modal('show')
                }, 750);
            }
        }
        else{
            await localStorage.clear()
            window.location.replace('/auth/login')
        }
    }

    async function navigateMenu(index, sub_menu_index){
        var sub_menu_index = menu_arr[index].arr[sub_menu_index]
        if(sub_menu_index.is_modal){
            console.log(default_input)
            var input_type = (default_input === 'camera_input' ? 'scan-barcode' : 'add-record')
            if(sub_menu_index.production_line.PDL_NFLAGTYPE == 0){
                set_tracking_id_selected(sub_menu_index.production_line.PDL_CID)
                set_tracking_type_selected(sub_menu_index.type)
                base.$(sub_menu_index.nav).modal('show')
            }
            else if(sub_menu_index.production_line.PDL_NFLAGTYPE == 1){
                await localStorage.setItem('tracking_process_id', sub_menu_index.production_line.PDL_CID)
                window.location.href = '/tracking/' + input_type + '?type=single&tracking-type=' + sub_menu_index.type
            }
            else if(sub_menu_index.production_line.PDL_NFLAGTYPE == 2){
                await localStorage.setItem('tracking_process_id', sub_menu_index.production_line.PDL_CID)
                window.location.href = '/tracking/' + input_type + '?type=multiple&tracking-type=' + sub_menu_index.type
            }
        }
        else{
            await localStorage.setItem('production_line_id', sub_menu_index.production_line.PDL_CID)
            window.location.href = sub_menu_index.nav
        }
    }


    return(
        <div className='page_wrap'>
            <div className='row'>

                <div className='col-auto'>
                    {/* <div className='ratio-31' style={{backgroundImage : 'url(' + base.img_logo + ')', width : '10rem'}}></div> */}
                    <div className='ratio-11' style={{backgroundImage : 'url(' + base.img_logo_daiken + ')', width : '3.25rem'}}></div>
                </div>

                <div className='col-12 mt-5' style={{marginBottom : '4.25rem'}}>
                    <div className='row'>
                        {
                            menu_arr.map((data, index)=>(
                                <div className={'col-12' + (index != 0 ? ' mt-3' : '')} key={index}>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <h5 className='m-0 text-capitalize'>{data.title}</h5>
                                        </div>
                                        <div className='col-12 mt-3'>
                                            <div className='row'>
                                                {
                                                    data.arr.map((sub_menu_data, sub_menu_index)=>(
                                                        <div className='col-4 mb-3' key={sub_menu_index}>
                                                            <div className='p-3 d-flex align-items-center justify-content-center shadow-sm h-100' style={{border : '1px solid #eaeaea', borderBottom : '4px solid #347AB5', cursor : 'pointer'}} onClick={()=>navigateMenu(index, sub_menu_index)}>
                                                                <div>
                                                                    <h4 className='m-0 text-center' style={{color : '#9FA2B4'}}><i className={sub_menu_data.icon}></i></h4>
                                                                    <p className='m-0 text-center mt-2' style={{fontSize : '.75rem', lineHeight : '1rem'}}>{sub_menu_data.title}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <ModalTracking tracking_type_selected={tracking_type_selected} tracking_id_selected={tracking_id_selected} />
            </div>
        </div>
    )
}