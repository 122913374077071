import { useEffect, useState } from 'react';
import Base from '../utils/base';

// import { QrReader } from 'react-qr-reader';
import QRScan from 'qrscan'
import FormInput from './FormInput';
import ModalInputQR from './modalInputQR';

export default function ScanCamera({resultScan, scan_type, viewFrom, type}){
    var base = new Base()

    const [qr_text, set_qr_text] = useState('')

    useEffect(()=>{
        base.$('#modalInputQR').on('hidden.bs.modal', function () {
            set_qr_text('')
        })
    }, [])

    useEffect(()=>{
        if(scan_type != null){
            set_qr_text('')
        }
    }, [scan_type])

    function searchQR(key){
        if(key === 'Enter'){
            if(qr_text !== ''){
                resultScan(qr_text)
            }
            else{
                base.$('#inputQR').focus()
            }
        }
    }

    function changeQRInput(value, type){
        var qr = value
        if(type === 'cam'){
            resultScan(value)
            qr = value.split('#')[0]
        }
        
        set_qr_text(qr)
    }

    function openModalInput(){
        base.$('#modalInputQR').modal('show')
    }

    return(
        <>
        <div className='row m-0'>
            <div className='col-12 p-0'>
                <QRScan onFind={(value)=>changeQRInput(value, 'cam')} />
            </div>

            {/* {
                viewFrom !== 'tracking' &&
                <div className='col-12 mt-3'>
                    <button className='btn btn-lg btn-primary w-100 rounded text-capitalize' onClick={()=>openModalInput()}>Input {scan_type != null ? scan_type : 'Palette'} ID</button>
                </div>
            } */}
        </div>

        {
            viewFrom !== 'tracking' &&
            <ModalInputQR qr_text={qr_text} changeQRInput={(value)=>changeQRInput(value, 'input')} searchQR={(key)=>searchQR(key)} type={scan_type} />
        }
        </>
    )
}