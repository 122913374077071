import { useEffect, useState } from 'react';
import Base from '../utils/base'
import FormInput from './FormInput';

export default function ModalInputQR({qr_text, changeQRInput, searchQR, type, keyUpInput}){
    var base = new Base()

    useEffect(()=>{
        base.$('#modalInputQR').on('shown.bs.modal', function () {
            base.$('#inputQR').trigger('focus')
        })
    }, [])

    return(
        <>
        <div className="modal fade" id="modalInputQR" tabIndex="-1" aria-labelledby="modalInputQRLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-dialog-scrollable m-0">
                <div className="modal-content border-0 shadow-sm position-fixed" style={{bottom : 0, borderTopLeftRadius : '1rem', borderTopRightRadius : '1rem'}}>
                    <div className="modal-body">
                        <div className={'row m-0'}>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col-12 p-0'>
                                        <FormInput
                                            label={'Input ID ' + type.charAt(0).toUpperCase() + type.slice(1)}
                                            type={'text'}
                                            value={qr_text.toUpperCase()}
                                            changeInput={(value)=>changeQRInput(value)}
                                            onKeyDown={(e)=>searchQR(e.key)}
                                            style={{textTransform : 'uppercase'}}
                                            id={'inputQR'} />
                                    </div>

                                    <div className='col-12 mt-4 p-0'>
                                        <button className='btn btn-lg btn-primary shadow-sm rounded w-100 text-capitalize' onClick={()=>searchQR('Enter')}>Submit {type}</button>
                                        <button className='btn btn-lg btn-outline-primary shadow-sm rounded w-100 mt-3' onClick={()=>searchQR('Cancel')}>
                                            {/* <i className={'bi bi-upc-scan mr-2'}></i> Scan Product QR Code */}
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}