import React, { useEffect, useState } from 'react';
import Base from '../../utils/base';

import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';
import ModalTracking from '../../components/TrackingModal';
import PageHeader from '../../components/pageHeader';
import MultipleScan from '../../components/multipleScan';
import PaletteList from '../../components/paletteList';
import SingleScan from '../../components/singleScan';

export default function HistoryDetail(){
    var base = new Base()

    const [search, set_search] = useState('')
    const [detail_data, set_detail_data] = useState({PDM_CID : '', PDM_DTIMESTAMP : '', PDM_DTIMESTAMP_FORMAT : '', prod_line : {PDL_CNAME : '', PDL_CCATEGORY : ''}})
    const [data_header_arr, set_data_header_arr] = useState([])
    const [detail_arr, set_detail_arr] = useState([])

    const [wh_type, set_wh_type] = useState('warehouse')

    function useQuery(){
        const {search} = useLocation()
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery()

    useEffect(async ()=>{
        // await localStorage.clear()
        var token = await localStorage.getItem('token')
        if(token == null){
            window.location.href = '/auth/login'
        }
        else{
            if(navigator.onLine){
                get_data()
            }
            else{
                base.$('#error_modal').modal('show')
            }
        }
    }, [])

    useEffect(async ()=>{
        get_data()
    }, [search])

    async function get_data(e){
        base.$('#preloader_modal').modal('show')

        var url = '/tracking/monitoring?id=' + query.get('id')
        var response = await base.request(url)
        if(response != null){
            if(response.status == 'success'){
                var data = response.data
                data.PDM_DTIMESTAMP_FORMAT = base.moment(data.PDM_DTIMESTAMP).format('DD MMMM YYYY - HH:mm')
                set_detail_data(data)

                data.desc = ''
                if(data.DESCRIPTION != null){
                    var split_desc = data.DESCRIPTION.split('_')
                    if(split_desc.length > 0){
                        for(var x in split_desc){
                            data.desc += split_desc[x] + ' '
                        }
                    }
                }

                var header_arr = [
                    {title : 'Process', value : data.prod_line.PDL_CCATEGORY + ' - ' + (data.DESCRIPTION == null ? data.prod_line.PDL_CNAME : data.desc)},
                    {title : 'Tanggal', value : data.PDM_DTIMESTAMP_FORMAT},
                ]

                
                if(query.get('type') === 'TRACKING'){
                    var status = data.STATUS.split('_')
                    var status_display = ''
                    if(status.length > 1){
                        for(var x in status){
                            status_display += status[x] + ' '
                        }
                    }
                    else{
                        status_display = status[0]
                    }
                    header_arr.push({title : 'Status', value : status_display})
                }
                set_data_header_arr(header_arr)

                var detail = data.detail
                var arr_detail = []
                for(var x in detail){
                    if(query.get('type') === 'TRACKING'){
                        detail[x].PDB_CDESCRIPTION = detail[x].production_barcode.PDB_CDESCRIPTION
                        detail[x].PDB_CBARCODE = detail[x].production_barcode.PDB_CBARCODE
                        detail[x].quantity = detail[x].QTY
                        arr_detail.push(detail[x])
                    }
                    else if(query.get('type') === 'PACKING'){
                        if(detail[x].packing_case != null){
                            detail[x].PAL_CID = detail[x].packing_case.PKC_CCODE
                            detail[x].quantity = detail[x].QTY
                            arr_detail.push(detail[x])
                        }
                        else{
                            if(detail[x].PAL_CID != null){
                                detail[x].PAL_CID = detail[x].PAL_CID
                                detail[x].quantity = detail[x].QTY
                                arr_detail.push(detail[x])
                            }
                        }
                    }
                    else if(query.get('type') === 'WAREHOUSE'){
                        if(detail[x].CIDDO == null){
                            detail[x].PDB_CDESCRIPTION = '-'
                            detail[x].PDB_CBARCODE = detail[x].PAL_CID
                            detail[x].quantity = detail[x].QTY
                            set_wh_type('warehouse')
                        }
                        else{
                            detail[x].PDB_CDESCRIPTION = '-'
                            detail[x].PAL_CID = detail[x].CIDDO
                            detail[x].quantity = detail[x].QTY
                            set_wh_type('back_to_warehouse')
                        }
                        arr_detail.push(detail[x])
                    }
                    else if(query.get('type') === 'INVENTORY' || query.get('type') === 'SHIPMENT'){
                        detail[x].PDB_CDESCRIPTION = '-'
                        detail[x].PDB_CBARCODE = detail[x].PAL_CID
                        detail[x].quantity = detail[x].QTY
                        arr_detail.push(detail[x])
                    }
                }

                set_detail_arr(arr_detail)

                base.hidePreloader()
            }
            else{
                base.hidePreloader()
                base.$('#error_modal').modal('show')
            }
        }
        else{
            base.hidePreloader()
            base.$('#error_modal').modal('show')
        }
    }

    async function changeSearch(value){
        set_search(value)
    }

    async function actionBtn(type, index){
        if(type === 'view'){
            if(query.get('type') !== 'PACKING'){
                window.location.href = '/inventory/detail-palette?id=' + detail_arr[index].PAL_CID
            }
            else{
                var data_detail = detail_data.detail
                var arr_product = []
                for(var x in data_detail){
                    if(data_detail[x].PDB_CID != null){
                        arr_product.push(data_detail[x])
                    }
                }
                var data = {
                    PAL_CID : detail_data.PDL_CID,
                    arr_item : arr_product
                }
                await localStorage.setItem('product_data', JSON.stringify(data))
                window.location.href = '/inventory/detail-palette?history_id=' + query.get('id')
            }
        }
    }

    return(
        <div className='page_wrap'>
            <div className='row'>

                <div className='col-12'>
                    <PageHeader title={query.get('id').split('SCAN_HISTORY_')[1]} style={{fontSize : '1.5rem', fontWeight : '600'}} is_back_btn={true} />
                </div>

                <div className='col-12 mt-5'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='row'>

                                {
                                    data_header_arr.map((data, index)=>(
                                        <div className='col-12 mb-2' key={index}>
                                            <div className='row'>
                                                <div className='col-4'>
                                                    <p className='m-0'>{data.title}</p>
                                                </div>
                                                <div className='col-8'>
                                                    <p className='m-0 font-weight-bold text-capitalize'>{data.value}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                        <div className='col-12'>
                            <div className='row m-0'>
                                <div className={'col-12' + (query.get('type') === 'TRACKING' ? (detail_arr.length == 1 ? ' p-0' : '') : '')}>
                                    {
                                        query.get('type') === 'TRACKING' ?
                                        <>
                                            {
                                                detail_arr.length > 1 ?
                                                <MultipleScan type={'product'} data_arr={detail_arr} actionBtn={(type, index)=>console.log(type, index)} viewFrom={'history'} />
                                                :
                                                <>
                                                    {
                                                        detail_arr[0] != null &&
                                                        <>
                                                        <SingleScan type={'product'} data={detail_arr[0]} />
                                                        <div className='col-12 p-0 mt-3 '>
                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <p className='m-0'>Quantity</p>
                                                                </div>
                                                                <div className='col-6'>
                                                                    <p className='m-0'>{detail_arr[0].QTY}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </>
                                                    }
                                                </>
                                            }
                                        </>
                                        : query.get('type') === 'PACKING' ?
                                        <>
                                            {/* <MultipleScan type={'product'} data_arr={detail_arr} actionBtn={(type, index)=>console.log(type, index)} viewFrom={'history'} /> */}
                                            <PaletteList data_arr={detail_arr} viewFrom={'history'} actionBtn={(type, index)=>actionBtn(type, index)} />
                                        </>
                                        : query.get('type') === 'INVENTORY' || query.get('type') === 'WAREHOUSE' || query.get('type') === 'SHIPMENT' ?
                                        <PaletteList data_arr={detail_arr} viewFrom={'history'} actionBtn={(type, index)=>actionBtn(type, index)} wh_type={wh_type} />
                                        : <></>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}