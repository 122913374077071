import React, { useEffect, useState } from 'react';
import Base from '../../utils/base';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import FormInput from '../../components/FormInput';
import SingleScan from '../../components/singleScan';
import MultipleScan from '../../components/multipleScan';
import ScanCamera from '../../components/scanCamera';
import ErrorQRModal from '../../components/errorQr';
import WrongProcessModal from '../../components/modalWrongProcess';

export default function TrackingScanBarcode(){
    var base = new Base()

    const [qty, set_qty] = useState(1)
    const [qr_result, set_qr_result] = useState({PDB_CID : '', PDB_CDESCRIPTION : '', PDB_CBARCODE : ''})

    const [multiple_qr_result_arr, set_multiple_qr_result_arr] = useState([])

    const [scan_type, set_scan_type] = useState('')
    const [tracking_type, set_tracking_type] = useState('')
    const [qr_mutation_arr, set_qr_mutation_arr] = useState([])
    const [count_barcode, set_count_barcode] = useState(1)

    const [is_find_qr, set_is_find_qr] = useState(true)
    const [production_line_id, set_production_line_id] = useState('')

    const [error_type_modal, set_error_type_modal] = useState('')
    const [error_message_modal, set_error_message_modal] = useState('')

    function useQuery(){
        const {search} = useLocation()
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery()

    useEffect(async ()=>{
        // await localStorage.clear()
        var token = await localStorage.getItem('token')
        if(token == null){
            window.location.href = '/auth/login'
        }
        else{
            set_scan_type(query.get('type'))
            set_tracking_type(query.get('tracking-type'))
            if(query.get('tracking-type') === 'MUTATION STOCK'){
                set_scan_type('single')
            }

            var qr_result_temp = await localStorage.getItem('qr_result_temp')
            if(qr_result_temp != null){
                qr_result_temp = JSON.parse(qr_result_temp)
                console.log(qr_result_temp)
                
                if(query.get('type') === 'single'){
                    set_qty(qr_result_temp.qty)
                    if(qr_result_temp.PDB_CID !== ''){
                        set_qr_result(qr_result_temp)
                    }
                }
                else if(query.get('type') === 'multiple'){
                    set_qty(qr_result_temp.length)
                    if(qr_result_temp.length > 0){
                        set_multiple_qr_result_arr(qr_result_temp)
                    }
                }
            }

            var tracking_process = await localStorage.getItem('tracking_process_id')
            if(tracking_process != null){
                set_production_line_id(await localStorage.getItem('tracking_process_id'))
            }
            else{
                window.location.replace('/')
            }
        }


        base.$('#modalResult').on('hidden.bs.modal', function () {
            set_is_find_qr(true)
        })
        base.$('#error_modal').on('hidden.bs.modal', function () {
            set_is_find_qr(true)
        })
        base.$('#modalErrorQR').on('hidden.bs.modal', function () {
            set_is_find_qr(true)
        })
        base.$('#modalWrongProcess').on('hidden.bs.modal', function () {
            set_is_find_qr(true)
        })

        // base.$('#modalResult').modal('show')
    }, [])

    useEffect(()=>{
        var flag = 1
        if(scan_type != ''){
            if(scan_type === 'single'){
                if(is_find_qr){
                    flag = 0
                }
                // if(qr_result.PDB_CID === ''){
                // }
            }
            else if(scan_type === 'multiple'){
                if(is_find_qr){
                    flag = 0
                }
            }

            if(flag){
                if(scan_type === 'single'){
                    submitBtn()
                }
                else{
                    base.$('#modalResult').modal('show')
                }
            }
        }
    }, [multiple_qr_result_arr, qr_result, scan_type])

    useEffect(async ()=>{
        if(tracking_type != ''){
            var qr_mutation_stock = await localStorage.getItem('qr_mutation_stock')
            if(qr_mutation_stock != null){
                qr_mutation_stock = JSON.parse(qr_mutation_stock)
                set_qr_mutation_arr(qr_mutation_stock)

                set_count_barcode(2)
            }
        }
    }, [tracking_type])

    async function submitBtn(){
        var result_data = null
        if(scan_type === 'multiple'){
            result_data = multiple_qr_result_arr
        }
        else if(scan_type === 'single'){
            result_data = qr_result
            result_data.qty = qty
        }

        var flag = 1

        if(tracking_type === 'MUTATION STOCK'){
            var qr_mutation_stock = await localStorage.getItem('qr_mutation_stock')
            if(qr_mutation_stock != null){
                qr_mutation_stock = JSON.parse(qr_mutation_stock)
                for(var x in qr_mutation_stock){
                    if(qr_mutation_stock[x].PDB_CID === result_data.PDB_CID){
                        flag = 0
                        set_error_type_modal('error')
                        set_error_message_modal('Product has already scanned')
                        base.$('#modalWrongProcess').modal('show')
                        break
                    }
                }
                qr_mutation_stock.push(result_data)
            }
            else{
                qr_mutation_stock = result_data
            }

            if(flag)
                await localStorage.setItem('qr_mutation_stock', JSON.stringify(qr_mutation_stock))
        }
        else{
            await localStorage.setItem('qr_result_temp', JSON.stringify(result_data))
        }
        
        if(flag){
            window.location.replace('/tracking/add-record?type=' + scan_type + '&tracking-type=' + query.get('tracking-type'))
        }
    }

    async function changeQty(value){
        var indexValue = value.length - 1
        var quantity = qty
        if(value.charCodeAt(indexValue) >= 48 && value.charCodeAt(indexValue) <= 57){
            quantity = value
        }
        else if((indexValue < 0)){
            quantity = ''
        }
        set_qty(quantity)
    }

    async function resultScan(value){
        if(is_find_qr){
            var flag = 1
            
            if(!navigator.onLine){
                flag = 0
                base.$('#error_modal').modal('show')
            }
            
            if(flag){
                set_is_find_qr(false)

                base.$('#preloader_modal').modal('show')

                var url = '/tracking?barcode=' + value.split('#')[0] + '&for=barcode&production_line_id=' + production_line_id
                
                var response = await base.request(url)
                if(response != null){
                    if(response.status == 'success'){
                        var data = response.data
                        if(data != null){
                            var flag_data = 1
                            if(data.previous_monitor != null){
                                if(data.previous_monitor.PDM_CPRODUCTIONLINE === 'PACKING'){
                                    flag_data = 0
                                    set_error_type_modal('error')
                                    set_error_message_modal(`Product is already in packing`)
                                    base.$('#modalWrongProcess').modal('show')
                                }
                                else if(data.previous_monitor.PDM_CPRODUCTIONLINE === 'WAREHOUSE'){
                                    flag_data = 0
                                    set_error_type_modal('error')
                                    set_error_message_modal(`Product is already in Warehouse`)
                                    base.$('#modalWrongProcess').modal('show')
                                }
                                else if(data.previous_monitor.PDM_CPRODUCTIONLINE === 'TEMP WAREHOUSE'){
                                    flag_data = 0
                                    set_error_type_modal('error')
                                    set_error_message_modal(`Product is already in Temp Warehouse`)
                                    base.$('#modalWrongProcess').modal('show')
                                }
                                else if(data.previous_monitor.PDM_CPRODUCTIONLINE === 'SHIPMENT'){
                                    flag_data = 0
                                    set_error_type_modal('error')
                                    set_error_message_modal(`Product is already in Shipment`)
                                    base.$('#modalWrongProcess').modal('show')
                                }
                                else if(data.previous_monitor.PDM_CPRODUCTIONLINE === 'MATERIAL'){
                                    flag_data = 0
                                    set_error_type_modal('error')
                                    set_error_message_modal(`Product is already in Material`)
                                    base.$('#modalWrongProcess').modal('show')
                                }
                            }
                            else{
                                flag_data = 1

                                if(data.last_monitor != null){
                                    if(data.last_monitor.PDM_CPRODUCTIONLINE === 'PACKING'){
                                        flag_data = 0
                                        set_error_type_modal('error')
                                        set_error_message_modal(`Product is already in packing`)
                                        base.$('#modalWrongProcess').modal('show')
                                    }
                                    else if(data.last_monitor.PDM_CPRODUCTIONLINE === 'WAREHOUSE'){
                                        flag_data = 0
                                        set_error_type_modal('error')
                                        set_error_message_modal(`Product is already in Warehouse`)
                                        base.$('#modalWrongProcess').modal('show')
                                    }
                                    else if(data.last_monitor.PDM_CPRODUCTIONLINE === 'TEMP WAREHOUSE'){
                                        flag_data = 0
                                        set_error_type_modal('error')
                                        set_error_message_modal(`Product is already in Temp Warehouse`)
                                        base.$('#modalWrongProcess').modal('show')
                                    }
                                    else if(data.last_monitor.PDM_CPRODUCTIONLINE === 'SHIPMENT'){
                                        flag_data = 0
                                        set_error_type_modal('error')
                                        set_error_message_modal(`Product is already in Shipment`)
                                        base.$('#modalWrongProcess').modal('show')
                                    }
                                    else if(data.last_monitor.PDM_CPRODUCTIONLINE === 'MATERIAL'){
                                        flag_data = 0
                                        set_error_type_modal('error')
                                        set_error_message_modal(`Product is already in Material`)
                                        base.$('#modalWrongProcess').modal('show')
                                    }
                                }

                                // if(tracking_type === 'MUTATION STOCK'){
                                //     if(count_barcode === 1){
                                //         flag_data = 0
                                //         set_error_type_modal('error')
                                //         set_error_message_modal(`Product must enter ASSEMBLY`)
                                //         base.$('#modalWrongProcess').modal('show')
                                //     }
                                // }   
                            }

                            if(data.last_monitor != null){
                                if(data.last_monitor.PDM_CPRODUCTIONLINE === 'PACKING'){
                                    flag_data = 0
                                    set_error_type_modal('error')
                                    set_error_message_modal(`Product is already in packing`)
                                    base.$('#modalWrongProcess').modal('show')
                                }

                                if(tracking_type === 'MUTATION STOCK'){
                                    if(count_barcode === 1){
                                        if(data.last_monitor.prod_line.PDL_CCATEGORY !== 'TRACKING'){
                                            flag_data = 0
                                            set_error_type_modal('error')
                                            set_error_message_modal(`Product already ` + data.last_monitor.prod_line.PDL_CCATEGORY)
                                            base.$('#modalWrongProcess').modal('show')
                                        }
                                        else{
                                            if(data.last_monitor.PDM_CPRODUCTIONLINE !== 'ASSEMBLY'){
                                                flag_data = 0
                                                set_error_type_modal('error')
                                                set_error_message_modal(`Product must enter ASSEMBLY`)
                                                base.$('#modalWrongProcess').modal('show')
                                            }
                                        }
                                    }
                                }
                            }
                            else{
                                if(tracking_type === 'MUTATION STOCK'){
                                    if(count_barcode === 1){
                                        flag_data = 0
                                        set_error_type_modal('error')
                                        set_error_message_modal(`Product must enter ASSEMBLY`)
                                        base.$('#modalWrongProcess').modal('show')
                                    }
                                }
                            }

                            if(data.PDB_NFLAGNG == 1){
                                if(data.prod_line != null){
                                    if(data.prod_line.PDL_CID !== production_line_id){
                                        flag_data = 0
                                        set_error_type_modal('error')
                                        set_error_message_modal('Product status not pass in ' + data.last_monitor.PDM_CPRODUCTIONLINE)
                                        base.$('#modalWrongProcess').modal('show')
                                    }
                                }
                            }

                            if(data.PDB_NFLAGOBSOLETE == 1){
                                flag_data = 0
                                set_error_type_modal('error')
                                set_error_message_modal(`Product ID doesn't have any sales order`)
                                base.$('#modalWrongProcess').modal('show')
                            }

                            if(flag_data){
                                if(tracking_type === 'MUTATION STOCK'){
                                    var flag_mutation = 1
                                    if(qr_mutation_arr.length < 2){
                                        for(var x in qr_mutation_arr){
                                            if(qr_mutation_arr[x].PDB_CID === data.PDB_CID){
                                                flag_mutation = 0
                                                set_error_type_modal('error')
                                                set_error_message_modal('Product has already scanned')
                                                base.$('#modalWrongProcess').modal('show')
                                                break
                                            }
                                        }
    
                                        if(flag_mutation){
                                            if(data.last_monitor != null){
                                                data.quantity = data.last_monitor.PDM_NQTY
                                            }
                                            base.add_array(qr_mutation_arr, set_qr_mutation_arr, data)
                                            set_count_barcode(2)
                                        }
                                    }
                                }
                                else{
                                    if(scan_type === 'multiple'){
                                        var new_flag = 1
                                        var data_arr = multiple_qr_result_arr
                                        for(var x in data_arr){
                                            if(data_arr[x].PDB_CID === data.PDB_CID){
                                                new_flag = 0
                                                data_arr[x].quantity += 1
                                                if(data.last_monitor != null){
                                                    data_arr[x].quantity = data.last_monitor.PDM_NQTY
                                                }
                                                base.update_array(multiple_qr_result_arr, set_multiple_qr_result_arr, data_arr[x], x)
                                            }
                                        }
                                        
                                        if(new_flag){
                                            data.quantity = 1
                                            if(data.last_monitor != null){
                                                data.quantity = data.last_monitor.PDM_NQTY
                                            }
                                            base.add_array(multiple_qr_result_arr, set_multiple_qr_result_arr, data)
                                        }
                                    }
                                    else if(scan_type === 'single'){
                                        if(data.last_monitor != null){
                                            if(data.last_monitor.PDM_NQTY == null){
                                                data.quantity = 1
                                                set_qty(1)
                                            }
                                            else{
                                                data.quantity = data.last_monitor.PDM_NQTY
                                                set_qty(data.last_monitor.PDM_NQTY)
                                            }
                                        }
                                        else{
                                            data.quantity = 1
                                            set_qty(1)
                                        }
                                        set_qr_result(data)
                                    }
                                }
                            }

                            base.hidePreloader()
                            // if(data.allow_scan){
                            // }
                            // else{
                            //     base.hidePreloader()
    
                            //     set_error_type_modal('tracking')
                            //     base.$('#modalWrongProcess').modal('show')
                            // }
                        }
                        else{
                            base.hidePreloader()
                            base.$('#modalErrorQR').modal('show')
                        }
                        
                    }
                    else{
                        base.hidePreloader()
                        base.$('#modalErrorQR').modal('show')
                    }
                }
                else{
                    base.hidePreloader()
                    base.$('#error_modal').modal('show')
                }
            }
        }
    }

    async function resultActionBtn(type, index){
        if(type === 'remove'){
            base.remove_array(multiple_qr_result_arr, set_multiple_qr_result_arr, index)
        }
    }

    async function backBtn(){
        var is_empty = 0
        if(scan_type === 'multiple'){
            if(multiple_qr_result_arr.length === 0){
                is_empty = 1
            }
        }
        else if(scan_type === 'single'){
            if(qr_result.PDB_CID === ''){
                is_empty = 1
            }
        }
        if(is_empty){
            await localStorage.removeItem('qr_result_temp')
        }
        window.history.back()
    }

    return(
        <>
            <div className='page_wrap'>
                <div className='row'>

                    <div className='col-12'>
                        <div className='row'>
                            <div className='col-auto d-flex align-items-center'>
                                <i className='fas fa-chevron-left' style={{cursor : 'pointer'}} onClick={()=>backBtn()}></i>  
                            </div>
                            <div className='col-auto d-flex align-items-center p-0'>
                                <p className='m-0' style={{fontSize : '1.25rem'}}>Scan Barcode</p>
                            </div>
                        </div>
                    </div>

                    <div className='col-12 mt-5 p-0' style={{height : '100%'}}>
                        <ScanCamera resultScan={(value)=>resultScan(value)} viewFrom={'tracking'} />
                    </div>

                </div>
            </div>

            <div className="modal fade" id="modalResult" tabIndex="-1" aria-labelledby="modalResultLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable m-0">
                    <div className="modal-content border-0 shadow-sm position-fixed" style={{bottom : 0, borderTopLeftRadius : '1rem', borderTopRightRadius : '1rem', maxHeight : '60vh'}}>
                        <div class="modal-header" style={{backgroundColor : '#E1EAEF', borderTopLeftRadius : '1rem', borderTopRightRadius : '1rem'}}>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col p-0 d-flex align-items-center'>
                                        <p className='m-0'>Scan Result</p>
                                    </div>
                                    {
                                        scan_type === 'multiple' &&
                                        <div className='col p-0 d-flex align-items-center justify-content-end'>
                                            <div className='px-3 py-1' style={{backgroundColor : '#EAEAEA', border : '1px solid #347AB5', borderRadius : '5rem'}}>
                                                <p className='m-0' style={{color : '#347AB5', fontSize : '.75rem'}}>{multiple_qr_result_arr.length} Product Scan</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className={'row m-0'}>
                                <div className='col-12'>
                                    <div className='row'>
                                        {
                                            scan_type === 'single' ?
                                            <>
                                                <div className='col-12'>
                                                    <div className='row'>
                                                        <SingleScan type={'product'} data={qr_result} />
                                                    </div>
                                                </div>
                                                <div className='col-12 p-0 mt-4'>
                                                    <div className='row'>
                                                        <div className='col-auto'>
                                                            <p className='m-0'>{scan_type === 'single' ? 'Quantity' : 'Total Scan'}</p>
                                                        </div>
                                                        <div className='col'>
                                                            <FormInput
                                                                type={'number'}
                                                                value={qty}
                                                                changeInput={(value)=>changeQty(value)}
                                                                pattern={"[0-9]*"} inputMode={"numeric"} />
                                                            <p className='m-0 mt-1' style={{fontSize : '.65rem'}}>Ubah Quantity, bila Produk memiliki kuantitas lebih dari 1</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className='col-12'>
                                                    <MultipleScan type={'product'} data_arr={multiple_qr_result_arr} actionBtn={(type, index)=>resultActionBtn(type, index)} />
                                                </div>
                                            </>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button className='btn btn-lg btn-primary shadow-sm rounded w-100' onClick={()=>submitBtn()}>Submit</button>

                        </div>
                        {/* <div className='position-fixed py-2 w-100 shadow-lg' style={{bottom : 0, backgroundColor : '#FAFAFA'}}>
                            <div className='row m-0'>
                                <div className='col-12'>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

            <ErrorQRModal />
            <WrongProcessModal process={query.get('tracking-type')} type={error_type_modal} message={error_message_modal} />
        </>
    )
}