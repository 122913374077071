import { useEffect, useState } from 'react';
import SingleScan from './singleScan';
import Base from '../utils/base'

export default function ResultModalInputID({palette_data, submitBtn, scan_type, product_data, process, deliver_order_data}){
    var base = new Base()

    const [action_btn_arr] = useState([
        {title : (process === 'view' ? 'View' : 'Proceed'), icon : '', btn_type : 'btn-primary', type : 'confirm'},
        {title : 'Reinput Palette ID', icon : 'bi bi-upc-scan', btn_type : 'btn-outline-primary', type : 'retype'},
    ])

    return(
        <>
        <div className="modal fade" id="modalResult" tabIndex="-1" aria-labelledby="modalResultLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable m-0">
                <div className="modal-content border-0 shadow-sm position-fixed" style={{bottom : 0, borderTopLeftRadius : '1rem', borderTopRightRadius : '1rem'}}>
                    <div class="modal-header" style={{backgroundColor : '#E1EAEF', borderTopLeftRadius : '1rem', borderTopRightRadius : '1rem'}}>
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col p-0 d-flex align-items-center'>
                                    <p className='m-0'>Validation {scan_type === 'palette' ? 'Result' : 'ID Product'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className={'row m-0'}>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='row'>
                                            {
                                                scan_type === 'delivery_order' ?
                                                <div className='row'>
                                                    <div className='col-12 p-3 mt-3' style={{backgroundColor : '#FCFCFC', border : '1px solid #EAEAEA', borderRadius : '.5rem'}}>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='row'>
                                                                    <div className='col'>
                                                                        <p className='m-0'>{deliver_order_data.CIDDO}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-12'>
                                                                <p className='m-0'>{deliver_order_data.CREQUESTNO}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <SingleScan type={scan_type} data={(scan_type === 'palette' ? palette_data : product_data)} />
                                            }
                                        </div>
                                    </div>
                                    <div className='col-12 mt-4 p-0'>
                                    {
                                        action_btn_arr.map((data, index)=>(
                                            <button className={'btn btn-lg w-100 rounded text-capitalize ' + (data.btn_type) + (index != 0 ? ' mt-3' : '')} onClick={()=>submitBtn(data.type)} key={index}>
                                                {
                                                    data.icon !== '' &&
                                                    <i className={data.icon + ' mr-2'}></i>
                                                }
                                                {data.title}
                                            </button>
                                        ))
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}