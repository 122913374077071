import React, { Component } from 'react'
// import '../App.css'
import axios from 'axios'
import moment from 'moment'
import Jquery from 'jquery'

import 'popper.js'
import 'bootstrap'

export default class Base extends Component {

	// base_url = 'https://daiken-api.quantumtri.com/api';
	// base_url = 'https://apipwa-daiken.solusiqu.com/api';
	base_url = 'https://ddi-factory.co.id:4433/api'

	// base_url = 'http://daiken-api.quantumtri.com'
	// url_image = 'https://lms-admin.quantumtri.com/image';

	timeoutAxios = 300000
	currencyFormat = 'id-ID'

	axios = axios
	moment = moment
	$ = Jquery

	constructor(props) {
		super(props)
		axios.defaults.headers.common['Content-Type'] = 'application/json'
		axios.defaults.headers.common['Accept'] = 'application/json'
		axios.defaults.timeout = this.timeoutAxios
	}

	async request(url, method = "get", data = {}, onUploadProgress = response => { }) {
		var token = await localStorage.getItem('token')
		if (token != null) {
			axios.defaults.headers.common['Authorization'] = token
		}

		try {
			var response
			if (method === 'get') {
				response = await axios.get(this.base_url + url)
			}
			else if (method === 'post') {
				response = await axios.post(this.base_url + url, data, {
					headers: { "Content-Type": "application/json" },
					onUploadProgress
				})
			}
			else if (method === 'put') {
				response = await axios.put(this.base_url + url, data, {
					headers: { "Content-Type": "application/json" },
					onUploadProgress
				})
			}
			else if (method === 'delete') {
				response = await axios.delete(this.base_url + url)
			}

			if (response.status !== 401) {
				return response.data
			}
			else {
				localStorage.clear()
				window.location.href = '/auth/login'
			}
		} catch (e) {
			this.hidePreloader()
			this.$('#error_modal').modal('show')
			if (e.message === 'Request failed with status code 401') {
				await localStorage.clear()
				window.location.replace('/auth/login')
			}
			else {
				return e
			}
			//   setTimeout(() => {
			// 	console.log(this.base_url + url, e)

			// 	if(e.message === 'Network Error'){
			// 	}
			//   }, 500)
		}
	}

	img_no_image = require('../assets/img/no_image_available.jpeg')
	img_no_profile = require('../assets/img/no_profile_picture.png')
	img_logo = require('../assets/img/logo.png')
	img_logo_daiken = require('../assets/img/daiken.png')
	img_success = require('../assets/img/success.png')
	img_loading = require('../assets/img/loading.gif')

	img_scan_qr = require('../assets/img/scan_qr.png')

	async update_array(arr, set_state, data = {}, index = 0) {
		var temp = [...arr]
		temp[index] = data
		set_state(temp)
	}

	add_array(arr, set_state, data = {}) {
		var temp = [...arr]
		temp.push(data)
		set_state(temp)
	}

	update_object(data, set_state, variable = null, key = '') {
		var temp = JSON.parse(JSON.stringify(data))
		temp[key] = variable
		set_state(temp)
	}

	remove_array(arr, set_state, index = 0) {
		var temp = [...arr]
		temp.splice(index, 1)
		set_state(temp)
	}

	url_photo(type, file_name) {
		var image_url = this.url_image + '/' + type + '?file_name=' + file_name + '&rnd=' + moment().format('HHmmss')
		return image_url
	}

	hidePreloader() {
		setTimeout(() => {
			this.$('#preloader_modal').modal('hide')
		}, 750);
	}

	async get_default_input() {
		var url = '/auth/profile'
		var response = await this.request(url)
		if (response != null) {
			if (response.status == 'success') {
				var data = response.data
				return data.DEFAULT_INPUT
			}
		}
	}

}