import { useEffect, useState } from 'react';
import Base from '../utils/base'

export default function NoData(){
    var base = new Base()

    return(
        <>
        <div className={'col-12 mt-3 d-flex align-items-center justify-content-center'}>
            <p className='m-0 font-weight-bold' style={{fontSize : '1.25rem'}}>No Data...</p>
        </div>
        </>
    )
}