import React, { useEffect, useState } from 'react';
import Base from '../../utils/base';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import SingleScan from '../../components/singleScan';
import MultipleScan from '../../components/multipleScan';
import PageHeader from '../../components/pageHeader';
import FormInput from '../../components/FormInput';
import ResultModalInputID from '../../components/InputIDResultModal';
import WrongProcessModal from '../../components/modalWrongProcess';
import InputQRForm from '../../components/inputQRForm';
import ErrorModal from '../../components/errorModal';
import ModalPaletteResult from '../../components/modalPaletteResult';

export default function WarehouseInputID(){
    var base = new Base()
    function useQuery(){
        const {search} = useLocation()
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery()

    const [input_type, set_input_type] = useState('')
    const [qr_text, set_qr_text] = useState('')
    const [palette_result_data, set_palette_result_data] = useState({PAL_CID : ''})
    const [production_line_id, set_production_line_id] = useState('')

    const [error_message_modal, set_error_message_modal] = useState('')

    const [arr_palette, set_arr_palette] = useState([])

    const [search_callback, set_search_callback] = useState('')

    useEffect(async ()=>{
        var token = await localStorage.getItem('token')
        if(token == null){
            window.location.href = '/auth/login'
        }

        var production_line_id = await localStorage.getItem('production_line_id')
        set_production_line_id(production_line_id)

        var data_palette = await localStorage.getItem('packing_input_palette')
        if(data_palette != null){
            data_palette = JSON.parse(data_palette)
            set_arr_palette(data_palette)
        }

        base.$('#modalResult').on('hidden.bs.modal', function (event) {
            set_qr_text('')
        })

        base.$('#preloader_modal').on('hidden.bs.modal', function (event) {
            base.$('#inputQR').trigger('focus')
        })
    }, [])

    useEffect(()=>{
        if(palette_result_data.PAL_CID !== ''){
            if(query.get('input_type') === 'palette'){
                base.hidePreloader()
                // base.$('#modalResult').modal('show')
            }
        }

    }, [palette_result_data])

    useEffect(async ()=>{
        if(qr_text === ''){
            base.$('#modalResult').modal('hide')
        }
    }, [qr_text])

    useEffect(()=>{
        set_qr_text('')
    }, [arr_palette])

    function searchQR(key){
        if(key === 'Enter'){
            if(qr_text !== ''){
                resultQR()
            }
            else{
                base.$('#inputQR').focus()
            }
        }
        else if(key === 'Cancel'){
            if(arr_palette.length > 0){
                submitBtn('submit')
            }
            else{
                window.history.back()
            }
        }
    }

    function changeQRInput(value){
        var qr = value.toUpperCase()
        set_qr_text(qr)
    }

    async function resultQR(){
        if(navigator.onLine){
            var barcode = qr_text
            var url = '/palette?barcode=' + barcode + '&production_line_id=' + production_line_id

            base.$('#preloader_modal').modal('show')

            var response = await base.request(url)
            if(response != null){
                if(response.status == 'success'){
                    var data = response.data
                    var flag_scan = 0

                    if(data != null){
                        if(query.get('type') === 'back_to_packing'){
                            if(data.prod_line.PDL_CNAME === 'PACKING'){
                                base.hidePreloader()
                                set_error_message_modal(`The Current Pallete ID status is still in Packing, can't be process`)
                                base.$('#modalWrongProcess').modal('show')
                            }
                            else if(data.prod_line.PDL_CNAME === 'SHIPMENT'){
                                base.hidePreloader()
                                set_error_message_modal(`The Current Pallete ID status is already in Shipment, can't be process`)
                                base.$('#modalWrongProcess').modal('show')
                            }
                            else{
                                set_palette_result_data({PAL_CID : ''})
                                flag_scan = 1
                            }

                            if(query.get('is_temp') != null){
                                if(query.get('is_temp') == 1){
                                    if(data.prod_line.PDL_CNAME === 'WAREHOUSE'){
                                        flag_scan = 0
                                        base.hidePreloader()
                                        set_error_message_modal(`Pallete ID must be in TEMP WAREHOUSE, can't be process`)
                                        base.$('#modalWrongProcess').modal('show')
                                    }
                                }
                            }
                            else{
                                if(data.prod_line.PDL_CNAME === 'TEMP WAREHOUSE'){
                                    flag_scan = 0
                                    base.hidePreloader()
                                    set_error_message_modal(`Pallete ID must be in WAREHOUSE, can't be process`)
                                    base.$('#modalWrongProcess').modal('show')
                                }
                            }
                        }
                        else{
                            if(data.prod_line.PDL_CNAME === 'PACKING'){
                                flag_scan = 1
                            }
                            else if(data.prod_line.PDL_CNAME === 'SHIPMENT'){
                                base.hidePreloader()
                                set_error_message_modal(`The Current Pallete ID status is already in Shipment, can't be process`)
                                base.$('#modalWrongProcess').modal('show')
                            }
                            else{
                                base.hidePreloader()
                                set_error_message_modal(`The Current Pallete ID has already been Scanned`)
                                base.$('#modalWrongProcess').modal('show')
                            }

                            for(var x in arr_palette){
                                if(data.PAL_CID === arr_palette[x].PAL_CID){
                                    flag_scan = 0
                                    base.hidePreloader()
                                    set_error_message_modal(`The Current Pallete ID has already been Scanned`)
                                    base.$('#modalWrongProcess').modal('show')
                                    break
                                }
                            }
                        }

                        if(flag_scan){
                            if(data.PAL_NFLAGTYPE == 0){
                                base.hidePreloader()
                                var result = data
                                result.data_type = 'old'
                                var flag_result = 1

                                for(var x in arr_palette){
                                    if(arr_palette[x].PAL_CID === result.PAL_CID){
                                        flag_result = 0
                                        break
                                    }
                                }

                                if(flag_result){
                                    result.quantity = 1
                                    base.add_array(arr_palette, set_arr_palette, result)
                                    // base.$('#modalResult').modal('show')
                                }

                                // set_palette_result_data(result)
                            }
                            else{
                                base.hidePreloader()
                            }
                        }
                        else{
                            set_qr_text('')
                        }
                        base.hidePreloader()
                    }
                    else{
                        base.hidePreloader()
                        set_qr_text('')

                        set_error_message_modal('Data not found')
                        base.$('#modalWrongProcess').modal('show')
                    }

                }
                else{
                    base.hidePreloader()
                    base.$('#modalErrorQR').modal('show')
                }
            }
            else{
                base.hidePreloader()
                base.$('#error_modal').modal('show')
            }
        }
    }

    function keyUpInput(e){
        if(e.keyCode >= 65 && e.keyCode <= 90 || e.keyCode >= 48 && e.keyCode <= 57 || e.keyCode !== 32 && e.keyCode !== 8 && e.keyCode !== 16 && e.keyCode !== 20 && e.keyCode !== 44 && e.keyCode !== 13){
            if(qr_text.length > 5){
                clearTimeout(search_callback)
                set_search_callback(
                    setTimeout(() => {
                        resultQR()
                    }, 500)
                )
            }
        }
    }

    async function submitBtn(type){
        if(type === 'retype'){
            set_palette_result_data({})
            set_qr_text('')
        }
        else{
            // arr_palette.push(palette_result_data)
            await localStorage.setItem('packing_input_palette', JSON.stringify(arr_palette))

            var url = '/warehouse/input-id/check?type=' + query.get('type')
            if(query.get('is_temp') != null){
                if(query.get('is_temp') == 1){
                    url += '&is_temp=1'
                }
            }
            window.location.href = url
        }
    }

    return(
        <>
            <div className='page_wrap'>
                <div className='row'>

                    <div className='col-12'>
                        <PageHeader title={(query.get('type') === 'back_to_packing' ? 'Back to Packing' : query.get('type') === 'warehouse' ? 'Collect to Warehouse' : '')} icon={(query.get('type') === 'remove' ? 'bi bi-dash-circle-fill' : null)} style={{fontSize : '1.25rem', fontWeight : '600'}} is_back_btn={true} />
                    </div>

                    <InputQRForm input_type={input_type} qr_text={qr_text} changeQRInput={(val)=>changeQRInput(val)} searchQR={(key)=>searchQR(key)} keyUpInput={(e)=>keyUpInput(e)} total_data={arr_palette.length} />

                </div>
            </div>

            <WrongProcessModal type={'error'} message={error_message_modal} />
            <ModalPaletteResult data_arr={arr_palette} viewFrom={'history'} wh_type={''} submitBtn={(type)=>submitBtn(type)} />

        </>
    )
}