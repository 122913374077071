import React, { useEffect, useState } from 'react';
import Base from '../../utils/base';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import FormInput from '../../components/FormInput';
import SingleScan from '../../components/singleScan';
import MultipleScan from '../../components/multipleScan';
import PageHeader from '../../components/pageHeader';
import ModalInputQR from '../../components/modalInputQR';
import WrongProcessModal from '../../components/modalWrongProcess';
import ErrorQRModal from '../../components/errorQr';
import TrackingButton from '../../components/trackingBtn';
import moment from 'moment';

export default function AddTrackingRecord() {
    var base = new Base()

    const [status_arr, set_status_arr] = useState([
        { title: 'OK', value: 'ok', icon: 'bi bi-check-circle-fill', is_selected: true },
        { title: 'Not Pass', value: 'not_pass', icon: 'bi bi-x-circle-fill', is_selected: false },
    ])
    const [status_selected, set_status_selected] = useState({ value: 'ok' })

    const [action_btn_arr, set_action_btn_arr] = useState([
        { title: 'Scan Product Barcode', icon: 'bi bi-upc-scan', btn_type: 'btn-outline-primary', type: 'scan', is_show: true },
        { title: 'Submit', icon: '', btn_type: 'btn-primary', type: 'submit', is_show: true },
        { title: 'Cancel', icon: '', btn_type: 'btn-link text-primary', type: 'cancel', is_show: true },
    ])

    const [qty, set_qty] = useState('')
    const [qr_result, set_qr_result] = useState({ PDB_CID: '', PDB_CDESCRIPTION: '', PDB_CBARCODE: '', qty: '' })


    const [scan_type, set_scan_type] = useState('')
    const [tracking_type, set_tracking_type] = useState('')

    const [is_button_disable, set_is_button_disable] = useState(false)

    const [is_status_error, set_is_status_error] = useState(false)

    const [multiple_qr_result_arr, set_multiple_qr_result_arr] = useState([])
    const [production_line_id, set_production_line_id] = useState('')

    const [qr_mutation_arr, set_qr_mutation_arr] = useState([])
    const [count_barcode, set_count_barcode] = useState(1)

    const [is_no_product, set_is_no_product] = useState(false)

    const [qr_text, set_qr_text] = useState('')

    const [error_type_modal, set_error_type_modal] = useState('')
    const [error_message_modal, set_error_message_modal] = useState('')

    const [search_callback, set_search_callback] = useState('')

    const [default_input, set_default_input] = useState('')

    const [is_search_qr, set_is_search_qr] = useState(true)

    function useQuery() {
        const { search } = useLocation()
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery()

    useEffect(async () => {
        // await localStorage.clear()
        // await localStorage.removeItem('qr_result_temp')


        var token = await localStorage.getItem('token')
        if (token == null) {
            window.location.href = '/auth/login'
        }
        else {
            set_scan_type(query.get('type'))
            set_tracking_type(query.get('tracking-type'))
            if (query.get('tracking-type') === 'MUTATION STOCK') {
                set_scan_type('single')
            }

            var get_default_input = await base.get_default_input()
            set_default_input(get_default_input)
            base.$('#modalInputQR').modal('show')

        }


    }, [])

    // useEffect(async ()=>{
    //     if(default_input === 'manual_input'){
    //     }
    // }, [default_input, scan_type, tracking_type])

    useEffect(async () => {
        if (scan_type != '') {
            var qr_result_temp = await localStorage.getItem('qr_result_temp')
            if (qr_result_temp != null) {
                qr_result_temp = JSON.parse(qr_result_temp)
                console.log(qr_result_temp)

                if (scan_type === 'single') {
                    var qty_result = 1
                    if (qr_result_temp.qty != null) {
                        qty_result = qr_result_temp.qty
                    }
                    set_qty(qty_result)
                    base.update_object(qr_result, set_qr_result, qty_result, 'qty')
                    if (qr_result_temp.PDB_CID !== '') {
                        var action_btn_index = action_btn_arr[0]
                        action_btn_index.title = 'Rescan Product Barcode'
                        base.update_array(action_btn_arr, set_action_btn_arr, action_btn_index, 0)
                        set_qr_result(qr_result_temp)
                    }
                }
                else if (scan_type === 'multiple') {
                    set_qty(qr_result_temp.length)
                    if (qr_result_temp.length > 0) {
                        set_multiple_qr_result_arr(qr_result_temp)
                    }
                }
            }

            var temp_status_selected = await localStorage.getItem('temp_status_selected')
            if (temp_status_selected != null) {
                temp_status_selected = JSON.parse(temp_status_selected)
                set_status_selected(temp_status_selected)

                var arr_status = status_arr
                for (var x in arr_status) {
                    arr_status[x].is_selected = false
                    if (arr_status[x].value === temp_status_selected.value) {
                        arr_status[x].is_selected = true
                        base.update_array(status_arr, set_status_arr, arr_status[x], x)
                    }
                }
            }
            set_production_line_id(await localStorage.getItem('tracking_process_id'))
        }
    }, [scan_type])

    useEffect(async () => {
        if (tracking_type != '') {
            var qr_mutation_stock = await localStorage.getItem('qr_mutation_stock')
            if (qr_mutation_stock != null) {
                qr_mutation_stock = JSON.parse(qr_mutation_stock)
                // if(qr_mutation_stock.length == 1){
                //     base.add_array(qr_mutation_arr, set_qr_mutation_arr, qr_mutation_stock)
                // }
                // else{
                // }
                set_qr_mutation_arr(qr_mutation_stock)

                set_count_barcode(2)
            }
        }
    }, [tracking_type])

    useEffect(async () => {
        if (multiple_qr_result_arr.length > 0) {
            set_qty(multiple_qr_result_arr.length)

            if(is_search_qr){
                setTimeout(() => {
                    base.$('#modalInputQR').modal('show')
                }, 500);
            }

            // base.$('#modalInputQR').on('hidden.bs.modal', function (event) {
            //     var temp = multiple_qr_result_arr.sort((a,b)=>a.PDB_CID > b.PDB_CID ? 1 : -1)
            //     set_multiple_qr_result_arr(temp)
            // })
        }
    }, [multiple_qr_result_arr])

    useEffect(() => {
        if (qr_result !== '') {
            // base.$('#modalInputQR').modal('hide')
        }
    }, [qr_result])

    useEffect(() => {
        if (qr_mutation_arr.length === 2) {
            base.$('#modalInputQR').modal('hide')
        }
        else if (qr_mutation_arr.length < 2) {
            setTimeout(() => {
                base.$('#modalInputQR').modal('show')
            }, 500);
        }
    }, [qr_mutation_arr])

    function changeStatus(index) {
        set_is_status_error(false)

        var status_index = status_arr[index]
        var init_selected = status_index.is_selected

        for (var x in status_arr) {
            status_arr[x].is_selected = false
        }

        status_index.is_selected = !init_selected

        if (!status_index.is_selected) {
            set_status_selected({ value: '' })
        }
        else {
            set_status_selected(status_index)
        }
        base.update_array(status_arr, set_status_arr, status_index, index)

    }

    async function actionBtn(type) {
        if (type === 'scan') {
            set_is_no_product(false)
            if (tracking_type === 'MUTATION STOCK') {
                await localStorage.setItem('qr_mutation_stock', JSON.stringify(qr_mutation_arr))
            }
            else {
                if (status_selected.value !== '') {
                    await localStorage.setItem('temp_status_selected', JSON.stringify(status_selected))
                }
                if (scan_type === 'single') {
                    await localStorage.setItem('qr_result_temp', JSON.stringify(qr_result))
                }
                else if (scan_type === 'multiple') {
                    await localStorage.setItem('qr_result_temp', JSON.stringify(multiple_qr_result_arr))
                }
            }
            window.location.href = '/tracking/scan-barcode?type=' + scan_type + '&tracking-type=' + tracking_type
        }
        else if (type === 'input') {
            base.$('#modalInputQR').modal('show')
        }
        else if (type === 'submit') {
            var flag = 1

            if (status_selected.value === '') {
                flag = 0
                set_is_status_error(true)
            }


            if (qr_result.last_monitor != null) {
                if (qr_result.last_monitor.PDM_CIDPRODUCTIONLINE === production_line_id) {
                    if (qr_result.last_monitor.STATUS === status_selected.value) {
                        flag = 0

                        var status = status_selected.value.split('_')
                        var status_display = ''
                        for (var x in status) {
                            status_display += status[x] + ' '
                        }

                        set_error_type_modal('error')
                        set_error_message_modal(`Product already ` + status_display.toUpperCase() + 'in ' + qr_result.last_monitor.PDM_CPRODUCTIONLINE)
                        base.$('#modalWrongProcess').modal('show')
                    }
                }
            }


            if (flag) {
                if (navigator.onLine) {
                    var url = ''
                    if (tracking_type !== 'MUTATION STOCK') {
                        url = '/tracking'

                        var arr_product = []

                        if (scan_type === 'single') {
                            if (qr_result.PDB_CID !== '') {
                                arr_product.push({
                                    id: qr_result.PDB_CID,
                                    qty: qr_result.qty,
                                })
                            }
                        }
                        else if (scan_type === 'multiple') {
                            for (var x in multiple_qr_result_arr) {
                                arr_product.push({
                                    id: multiple_qr_result_arr[x].PDB_CID,
                                    qty: multiple_qr_result_arr[x].quantity,
                                })
                            }
                        }

                        var data = {
                            arr_product: arr_product,
                            production_line: { id: production_line_id },
                            status: status_selected.value
                        }

                        if (arr_product.length > 0) {
                            base.$('#preloader_modal').modal('show')

                            set_is_button_disable(true)

                            var response = await base.request(url, 'put', data)
                            if (response != null) {
                                if (response.status == 'success') {
                                    await localStorage.removeItem('qr_result_temp')
                                    await localStorage.removeItem('temp_status_selected')
                                    window.location.replace('/success?pdl=tracking&type=' + scan_type + '&tracking-type=' + tracking_type)
                                }
                                else {
                                    setTimeout(() => {
                                        base.$('#preloader_modal').modal('hide')
                                    }, 750);
                                    base.$('#error_modal').modal('show')
                                    set_is_button_disable(false)
                                }
                            }
                            else {
                                setTimeout(() => {
                                    base.$('#preloader_modal').modal('hide')
                                }, 750);
                                base.$('#error_modal').modal('show')
                                set_is_button_disable(false)
                            }
                        }
                        else {
                            set_is_no_product(true)
                        }
                    }
                    else {
                        url = '/tracking/barcode-mutation'

                        if (qr_mutation_arr.length === 2) {
                            var data = {
                                barcode1: qr_mutation_arr[0].PDB_CID,
                                barcode2: qr_mutation_arr[1].PDB_CID,
                                status_barcode1: 'ok',
                                status_barcode2: 'ok',
                                qty_barcode1: 1,
                                qty_barcode2: 1
                            }

                            var response = await base.request(url, 'post', data)
                            if (response != null) {
                                if (response.status == 'success') {
                                    await localStorage.removeItem('qr_result_temp')
                                    await localStorage.removeItem('temp_status_selected')
                                    await localStorage.removeItem('qr_mutation_temp')
                                    window.location.replace('/success?pdl=tracking&type=' + scan_type + '&tracking-type=' + tracking_type)
                                }
                                else {
                                    setTimeout(() => {
                                        base.$('#preloader_modal').modal('hide')
                                    }, 750);
                                    base.$('#error_modal').modal('show')
                                    set_is_button_disable(false)
                                }
                            }
                            else {
                                setTimeout(() => {
                                    base.$('#preloader_modal').modal('hide')
                                }, 750);
                                base.$('#error_modal').modal('show')
                                set_is_button_disable(false)
                            }

                        }

                    }

                }
                else {
                    setTimeout(() => {
                        base.$('#preloader_modal').modal('hide')
                    }, 750);
                    base.$('#error_modal').modal('show')
                }
            }

        }
        else if (type === 'cancel') {
            await localStorage.removeItem('qr_result_temp')
            await localStorage.removeItem('temp_status_selected')
            await localStorage.removeItem('qr_mutation_stock')
            window.location.replace('/')
        }
        else if (type === 're-scan') {
            await localStorage.removeItem('qr_mutation_stock')
            set_count_barcode(1)
            set_qr_mutation_arr([])
        }
    }

    async function resultActionBtn(type, index) {
        if (type === 'remove') {
            base.remove_array(multiple_qr_result_arr, set_multiple_qr_result_arr, index)
        }
    }

    function searchQR(key) {
        if (key === 'Enter') {
            if (qr_text !== '') {
                resultScan(qr_text)
            }
            else {
                base.$('#inputQR').focus()
            }
        }
        else if(key === 'Cancel'){
            set_is_search_qr(false)
            base.$('#modalInputQR').modal('hide')
        }
    }

    function changeQRInput(value) {
        set_qr_text(value)
    }

    function keyUpInput(e) {
        if (e.keyCode >= 65 && e.keyCode <= 90 || e.keyCode >= 48 && e.keyCode <= 57 || e.keyCode !== 32 && e.keyCode !== 8 && e.keyCode !== 16 && e.keyCode !== 20 && e.keyCode !== 44 && e.keyCode !== 13) {
            if (qr_text.length > 5) {
                clearTimeout(search_callback)
                set_search_callback(
                    setTimeout(() => {
                        resultScan(qr_text)
                    }, 500)
                )
            }
        }
    }

    async function resultScan(value) {
        var flag = 1

        if (!navigator.onLine) {
            flag = 0
            base.$('#error_modal').modal('show')
        }

        if (flag) {
            base.$('#preloader_modal').modal('show')

            var url = '/tracking?barcode=' + value.split('#')[0] + '&for=barcode&production_line_id=' + production_line_id

            var response = await base.request(url)
            if (response != null) {
                if (response.status == 'success') {
                    var data = response.data
                    set_qr_text('')
                    if (data != null) {
                        var flag_data = 1
                        if (data.prod_line != null) {
                            if (data.prod_line.PDL_CNAME === 'PACKING') {
                                flag_data = 0
                                set_error_type_modal('error')
                                set_error_message_modal(`Product is already in packing`)
                                base.$('#modalWrongProcess').modal('show')
                            }
                            else if (data.prod_line.PDL_CNAME === 'WAREHOUSE') {
                                flag_data = 0
                                set_error_type_modal('error')
                                set_error_message_modal(`Product is already in Warehouse`)
                                base.$('#modalWrongProcess').modal('show')
                            }
                            else if (data.prod_line.PDL_CNAME === 'TEMP WAREHOUSE') {
                                flag_data = 0
                                set_error_type_modal('error')
                                set_error_message_modal(`Product is already in Temp Warehouse`)
                                base.$('#modalWrongProcess').modal('show')
                            }
                            else if (data.prod_line.PDL_CNAME === 'SHIPMENT') {
                                flag_data = 0
                                set_error_type_modal('error')
                                set_error_message_modal(`Product is already in Shipment`)
                                base.$('#modalWrongProcess').modal('show')
                            }
                            else if (data.prod_line.PDL_CNAME === 'MATERIAL') {
                                flag_data = 0
                                set_error_type_modal('error')
                                set_error_message_modal(`Product is already in Material`)
                                base.$('#modalWrongProcess').modal('show')
                            }
                        }

                        if (data.last_monitor != null) {
                            // if(data.last_monitor.PDM_CIDPRODUCTIONLINE === production_line_id){
                            //     flag_data = 0
                            //     set_error_type_modal('tracking')
                            //     base.$('#modalWrongProcess').modal('show')
                            // }

                            if (tracking_type === 'MUTATION STOCK') {
                                if (count_barcode === 1) {
                                    if (data.last_monitor.prod_line.PDL_CCATEGORY !== 'TRACKING') {
                                        flag_data = 0
                                        set_error_type_modal('error')
                                        set_error_message_modal(`Product already ` + data.last_monitor.prod_line.PDL_CCATEGORY)
                                        base.$('#modalWrongProcess').modal('show')
                                    }
                                }
                            }
                        }

                        if (data.PDB_NFLAGNG == 1) {
                            if (data.prod_line != null) {
                                if (data.prod_line.PDL_CID !== production_line_id) {
                                    flag_data = 0
                                    set_error_type_modal('error')
                                    set_error_message_modal('Product status not pass in ' + data.last_monitor.PDM_CPRODUCTIONLINE)
                                    base.$('#modalWrongProcess').modal('show')
                                }
                            }
                        }

                        if (data.PDB_NFLAGOBSOLETE == 1) {
                            flag_data = 0
                            set_error_type_modal('error')
                            set_error_message_modal(`Product ID doesn't have any sales order`)
                            base.$('#modalWrongProcess').modal('show')
                        }

                        if (tracking_type === 'MUTATION STOCK') {
                            if (data.prod_line != null) {
                                console.log(data.prod_line.PDL_NDEFAULTLASTSTATE)
                                if (data.prod_line.PDL_NDEFAULTLASTSTATE != 1) {
                                    flag_data = 0
                                    set_error_type_modal('error')
                                    set_error_message_modal(`Product can't be process`)
                                    base.$('#modalWrongProcess').modal('show')
                                }
                            }
                        }

                        if (flag_data) {
                            base.$('#modalInputQR').modal('hide')
                            if (tracking_type === 'MUTATION STOCK') {
                                var flag_mutation = 1
                                if (qr_mutation_arr.length < 2) {
                                    for (var x in qr_mutation_arr) {
                                        if (qr_mutation_arr[x].PDB_CID === data.PDB_CID) {
                                            flag_mutation = 0
                                            set_error_type_modal('error')
                                            set_error_message_modal('Product has already scanned')
                                            base.$('#modalWrongProcess').modal('show')
                                            break
                                        }
                                    }

                                    if (flag_mutation) {
                                        if (data.last_monitor != null) {
                                            data.quantity = data.last_monitor.PDM_NQTY
                                        }
                                        base.add_array(qr_mutation_arr, set_qr_mutation_arr, data)
                                        set_count_barcode(2)
                                    }
                                }
                            }
                            else {
                                if (scan_type === 'multiple') {
                                    var new_flag = 1
                                    var data_arr = JSON.parse(JSON.stringify(multiple_qr_result_arr))
                                    var flag_already = 0
                                    for (var x in data_arr) {
                                        if (data_arr[x].PDB_CID === data.PDB_CID) {
                                            new_flag = 0
                                            flag_already = 1
                                            data_arr[x].quantity += 1
                                            if (data.last_monitor != null) {
                                                data_arr[x].quantity = data.last_monitor.PDM_NQTY
                                            }
                                            // base.update_array(multiple_qr_result_arr, set_multiple_qr_result_arr, data_arr[x], x)
                                        }
                                    }
                                    if (new_flag) {
                                        data.quantity = 1
                                        if (data.last_monitor != null) {
                                            data.quantity = data.last_monitor.PDM_NQTY
                                        }
                                        data_arr.push(data)
                                        // base.add_array(multiple_qr_result_arr, set_multiple_qr_result_arr, data)
                                    }
                                    data_arr = data_arr.sort((a,b)=>a.PDB_CID > b.PDB_CID ? 1 : -1)
                                    set_multiple_qr_result_arr(data_arr)
                                }
                                else if (scan_type === 'single') {
                                    if (data.last_monitor != null) {
                                        data.qty = data.last_monitor.PDM_NQTY
                                    }
                                    else {
                                        if (data.PDB_CID === qr_result.PDB_CID) {
                                            data.qty = parseInt(qr_result.qty) + 1
                                        }
                                        else if (data.PDB_CID !== qr_result.PDB_CID) {
                                            data.qty = 1
                                        }
                                    }
                                    set_qty(data.qty)
                                    set_qr_result(data)
                                }
                            }
                        }

                        base.hidePreloader()
                        // if(data.allow_scan){
                        // }
                        // else{
                        //     base.hidePreloader()

                        //     set_error_type_modal('tracking')
                        //     base.$('#modalWrongProcess').modal('show')
                        // }
                    }
                    else {
                        base.hidePreloader()
                        base.$('#modalErrorQR').modal('show')
                    }

                }
                else {
                    base.hidePreloader()
                    base.$('#modalErrorQR').modal('show')
                }
            }
            else {
                base.hidePreloader()
                base.$('#error_modal').modal('show')
            }
        }
    }

    async function changeQty(value) {
        var indexValue = value.length - 1
        var quantity = qty
        if (value.charCodeAt(indexValue) >= 48 && value.charCodeAt(indexValue) <= 57) {
            quantity = value
        }
        else if ((indexValue < 0)) {
            quantity = ''
        }
        set_qty(quantity)
        base.update_object(qr_result, set_qr_result, quantity, 'qty')

    }

    return (
        <>
            <div className='page_wrap pb-5 mb-5'>
                <div className='row'>

                    <div className='col-12'>
                        <PageHeader title={(scan_type === 'single' ? 'Add Tracking Record' : 'Multiple Product Scan')} style={{ fontSize: '1.25rem', fontWeight: '600' }} />
                    </div>

                    <div className='col-12 mt-5'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col-4 d-flex align-items-center'>
                                        <p className='m-0'>Process</p>
                                    </div>
                                    <div className='col-8'>
                                        <FormInput
                                            type={'text'}
                                            value={tracking_type}
                                            style={{ textTransform: 'capitalize' }}
                                            readOnly={true}
                                            changeInput={(value) => console.log(value)} />
                                    </div>
                                </div>
                            </div>

                            {
                                tracking_type !== 'MUTATION STOCK' &&
                                <div className='col-12 mt-4'>
                                    <div className='row m-0'>
                                        {
                                            status_arr.map((data, index) => (
                                                <div className={'col rounded p-3' + (index % 2 === 0 ? ' mr-1' : ' ml-1') + ' trackingStatus' + (data.is_selected ? ' selected' : '') + ' ' + (is_status_error ? ' border-danger' : '')} onClick={() => changeStatus(index)} key={index}>
                                                    <div className='row'>
                                                        <div className='col-auto'>
                                                            <i className={(data.icon) + ' ' + (is_status_error ? 'text-danger' : '')}></i>
                                                        </div>
                                                        <div className='col-auto p-0'>
                                                            <p className={'m-0 ' + (is_status_error ? 'text-danger' : '')} style={{ color: (is_status_error ? 'red' : '') }}>{data.title}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }

                                        {
                                            is_status_error &&
                                            <div className='col-12 p-0'>
                                                <p className='m-0 text-danger'>Please select 1 status</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }

                            <div className='col-12 mt-3'>
                                <div className='row m-0'>
                                    <div className='col-12 mt-3'>
                                        <div className='row'>
                                            {
                                                tracking_type === 'MUTATION STOCK' ?
                                                    <>
                                                        {
                                                            qr_mutation_arr.map((data, index) => (
                                                                <>
                                                                    <div className='col-12 px-0 py-3 d-flex align-items-center' style={{ borderBottom: '1px solid #EAEAEA' }}>
                                                                        <p className='m-0'>Barcode #{index + 1}</p>
                                                                    </div>

                                                                    <div className='col-12'>
                                                                        <div className='row'>
                                                                            <SingleScan type={'product'} data={data} />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ))
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            scan_type === 'single' ?
                                                                <>
                                                                    <div className='col-12 px-0 py-3 d-flex align-items-center' style={{ borderBottom: '1px solid #EAEAEA' }}>
                                                                        <p className='m-0'>Product</p>
                                                                    </div>

                                                                    {
                                                                        qr_result.PDB_CID !== '' &&
                                                                        <div className='col-12'>
                                                                            <div className='row'>
                                                                                <SingleScan type={'product'} data={qr_result} />
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    <div className='col-12'>
                                                                        <MultipleScan type={'product'} data_arr={multiple_qr_result_arr} actionBtn={(type, index) => resultActionBtn(type, index)} />
                                                                    </div>
                                                                </>
                                                        }
                                                    </>
                                            }

                                            {
                                                is_no_product ?
                                                    <div className='col-12 p-0 mt-4'>
                                                        <div class={"alert alert-danger m-0 rounded"} role="alert">No Product Added</div>
                                                    </div>
                                                    :
                                                    <></>
                                            }

                                            {
                                                tracking_type !== 'MUTATION STOCK' &&
                                                <div className='col-12 p-0 mt-4'>
                                                    <div className='row'>
                                                        <div className='col-4 d-flex align-items-center'>
                                                            <p className='m-0'>{scan_type === 'single' ? 'Quantity' : 'Total Scan'}</p>
                                                        </div>
                                                        <div className='col-8'>
                                                            <FormInput
                                                                type={'number'}
                                                                value={qty}
                                                                changeInput={(value) => changeQty(value)}
                                                                readOnly={(scan_type === 'multiple' ? 'readOnly' : '')}
                                                                pattern={"[0-9]*"} inputMode={"numeric"} />
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            {/* <div className='col-12 p-4 d-flex align-items-center justify-content-center'>
                                                <p className='m-0'>No Product Scanned</p>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className='position-fixed w-100 py-2 shadow-lg' style={{ bottom: 0, backgroundColor: '#FAFAFA' }}>
                <div className='row m-0'>

                    {
                        tracking_type === 'MUTATION STOCK' ?
                            <>
                                {
                                    qr_mutation_arr.length < 2 ?
                                        <TrackingButton count_barcode={count_barcode} actionBtn={(type) => actionBtn(type)} is_button_disable={is_button_disable} default_input={default_input} />
                                        :
                                        <>
                                            <div className='col-12'>
                                                <button className='btn btn-lg w-100 rounded btn-outline-primary customBtn' onClick={() => actionBtn('re-scan')} disabled={is_button_disable}>
                                                    <i className='bi bi-upc-scan'></i> Re-Input / Re-Scan Barcode
                                                </button>
                                            </div>
                                        </>
                                }
                            </>
                            :
                            <>
                                <TrackingButton count_barcode={0} actionBtn={(type) => actionBtn(type)} is_button_disable={is_button_disable} default_input={default_input} />
                            </>
                    }
                    <div className='col-12 mt-3'>
                        <div className='row'>
                            <div className='col-6 pr-1'>
                                <button className='btn btn-lg w-100 rounded btn-primary customBtn' onClick={() => actionBtn('submit')} disabled={is_button_disable}>
                                    Submit
                                </button>
                            </div>
                            <div className='col-6 pl-1'>
                                <button className='btn btn-lg w-100 rounded btn-link text-primary customBtn' onClick={() => actionBtn('cancel')} disabled={is_button_disable}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalInputQR qr_text={qr_text} changeQRInput={(value) => changeQRInput(value)} searchQR={(key) => searchQR(key)} keyUpInput={(e) => keyUpInput(e)} type={'Product'} />

            <ErrorQRModal />
            <WrongProcessModal process={tracking_type} type={error_type_modal} message={error_message_modal} />
        </>
    )
}