import { useEffect, useState } from 'react';
import Base from '../utils/base'

export default function WrongProcessModal({type, process, message}){
    var base = new Base()

    return(
        <>
        <div className="modal fade" id="modalWrongProcess" tabIndex="-1" aria-labelledby="modalWrongProcessLabel" aria-hidden="true">
                <div className="modal-dialog p-4 pt-5 mt-5">
                    <div className="modal-content border-0 shadow-sm" style={{borderRadius : '.75rem'}}>
                        <div className="modal-body">
                            <div className={'row'}>
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col-12 px-5'>
                                            {
                                                type === 'tracking' ?
                                                <p className='m-0 text-center' style={{fontSize : '1.25rem'}}>The current <b>Product ID</b> status is already in <b className='text-primary'>{process}</b></p>
                                                :
                                                type === 'packaging' ?
                                                <p className='m-0 text-center' style={{fontSize : '1.25rem'}}>The current <b>Product ID</b> status is in <b className='text-primary'>{process}</b> Product must enter <b className='text-primary'>WRAP</b> before <b>PACKING</b></p>
                                                :
                                                type === 'not_pass' ?
                                                <p className='m-0 text-center' style={{fontSize : '1.25rem'}}>Product Has Not Pass {message}, it can't be Pack</p>
                                                :
                                                <>
                                                <p className='m-0 text-center' style={{fontSize : '1.25rem'}}>{message}</p>
                                                </>
                                            }
                                        </div>
                                        <div className='col-12 mt-3 text-center'>
                                            <button type='button' className='btn btn-lg btn-primary rounded w-100' data-dismiss="modal">OK</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}