import { useEffect, useState } from 'react';
import Base from '../../utils/base';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import FormInput from '../../components/FormInput';
import AuthHeader from '../../components/AuthHeader';

export default function AuthLogin(){
    var base = new Base()

    const [login_form_arr, set_login_form_arr] = useState([{label : 'User name', input_type : 'text', type : 'username', value : '', is_error : false}, {label : 'Kata Sandi', input_type : 'password', type : 'password', value : '', is_error : false}])

    const [is_button_disable, set_is_button_disable] = useState(false)
    const [alert_data, set_alert_data] = useState({message : '', type : ''})

    useEffect(async ()=>{
        await localStorage.clear()
    }, [])

    async function changeInput(value, index){
        set_alert()
        set_is_button_disable(false)

        var data_index = login_form_arr[index]
        login_form_arr[index].value = value
        for(var x in login_form_arr){
            login_form_arr[x].is_error = false
        }
        base.update_array(login_form_arr, set_login_form_arr, data_index, index)
    }

    async function set_alert(message='', type=''){
        set_alert_data({message : message, type : type})
    }

    async function signIn(e){
        e.preventDefault()
        var flag = 1
        var data_login = {username : '', password : ''}

        for(var x in login_form_arr){
            if(login_form_arr[x].value === ''){
                login_form_arr[x].is_error = true
                base.update_array(login_form_arr, set_login_form_arr, login_form_arr[x], x)
                flag = 0
                break
            }
            else{
                data_login[login_form_arr[x].type] = login_form_arr[x].value
            }
        }
        
        if(flag){
            base.$('#preloader_modal').modal('show')
            set_is_button_disable(true)

            var url = '/auth/login'
            var response = await base.request(url, 'post', data_login)

            if(response != null){
                if(response.status == 'success'){
                    await localStorage.setItem('token', response.token)

                    set_alert('Login Success! Please Wait...', 'success')

                    setTimeout(() => {
                        window.location.href = '/'
                    }, 750);
                }
                else{
                    set_alert(response.message, 'danger')
                    setTimeout(() => {
                        base.$('#preloader_modal').modal('hide')
                    }, 750);
                    set_is_button_disable(false)
                }
            }
            else{
                setTimeout(() => {
                    base.$('#preloader_modal').modal('hide')
                }, 750);
                set_is_button_disable(false)
            }
        }
    }


    return(

        <div className='page_wrap'>
            <div className='row m-0'>

                <div className='col-12'>
                    <AuthHeader title={'Masuk'} is_back_btn={false} />
                </div>

                <div className='col-12 mt-5'>
                    <form className='row' onSubmit={(e)=>signIn(e)}>
                        {
                            alert_data.type != '' &&
                            <div className='col-12'>
                                <div class={"alert alert-" + (alert_data.type)} role="alert">
                                    {alert_data.message}
                                </div>
                            </div>
                        }

                        {
                            login_form_arr.map((data, index)=>(
                                <div className={"col-12 form-group m-0" + (index != 0 ? ' mt-3' : '')} key={index}>
                                    <FormInput
                                        label={data.label}
                                        type={data.type}
                                        value={data.value}
                                        changeInput={(value)=>changeInput(value, index)}
                                        is_error={data.is_error}
                                        error_message={(data.label) + ` can't be empty`} />
                                </div>
                            ))
                        }

                        <div className='col-12 mt-2'>
                            <a href='/auth/forget-password' className='text-primary' style={{textDecoration : 'underline'}}>Lupa Kata Sandi</a>
                        </div>

                        <div className='col-12 mt-4'>
                            <button type='submit' className='btn btn-lg btn-primary w-100 shadow-sm rounded' onClick={(e)=>signIn(e)} disabled={is_button_disable}>Masuk</button>
                            <a href='/auth/register' type='button' className='btn btn-lg btn-outline-primary shadow-sm rounded w-100 mt-3'>Registrasi</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}