import { useEffect, useState } from 'react';
import Base from '../utils/base'


export default function FormInput({label, type, value, changeInput, is_error, error_message, style, readOnly, ...props}){
    var base = new Base()

    const [is_show_password, set_is_show_password] = useState(false)
    const [is_show_retype_password, set_is_show_retype_password] = useState(false)

    const [input_type, set_input_type] = useState('text')

    useEffect(()=>{
        if(type === 'password' || type === 'retype_password'){
            set_input_type('password')
        }
    }, [])

    async function togglePassword(type){
        if(input_type === 'password'){
            set_input_type('text')
        }
        else if(input_type === 'text'){
            set_input_type('password')
        }
    }


    return(
        <>
        {
            label != null &&
            <label>{label}</label>
        }
        <div className={"input-group mb-0" + (type === 'password' || type === 'retype_password' ? ' form-password' : '')}>
            <input type={input_type} className={"form-control rounded" + (is_error ? ' border border-danger' : '')} autoComplete={type === 'password' || type === 'retype_password' ? 'one-time-code' : 'off'} value={value} onChange={(e)=>changeInput(e.target.value)} style={{...style}} readOnly={readOnly} {...props} />

            {
                (type === 'password' || type === 'retype_password') &&
                <div className="input-group-append">
                    <span className={"input-group-text rounded" + (is_error ? ' border border-danger' : '')} onClick={()=>togglePassword(type)}>
                        <i className={input_type === 'text' ? "bi bi-eye" : "bi bi-eye-slash"}></i>
                    </span>
                </div>
            }
        </div>
        {
            is_error &&
            <small className="form-text text-danger m-0">{error_message}</small>
        }
        </>
    )
}