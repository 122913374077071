import { useEffect, useState } from 'react';
import Base from '../utils/base'

export default function PageHeader({title, is_back_btn, style, icon, backToHome, customBack, backToPage}){
    var base = new Base()

    function backBtn(){
        if(window.history.length > 2){
            if(!customBack){
                if(backToHome){
                    window.location.replace('/')
                }
                else{
                    window.history.back()
                }
            }
            else{
                backToPage()
            }
        }
        else{
            window.location.replace('/')
        }
    }

    return(
        <>
        <div className='row'>
            {
                is_back_btn &&
                <div className='col-auto d-flex align-items-center'>
                    <i className='fas fa-chevron-left' style={{cursor : 'pointer'}} onClick={()=>backBtn()}></i>  
                </div>
            }
            <div className={'col-auto d-flex align-items-center' + (is_back_btn ? ' p-0' : '')}>
                <p className='m-0' style={{fontSize : '1.25rem', ...style}}>
                    {
                        icon != null &&
                        <i className={icon + ' mr-2'}></i>
                    }
                    {title}
                </p>
            </div>
        </div>
        </>
    )
}