import { useEffect, useState } from 'react';
import Base from '../../utils/base';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PageHeader from '../../components/pageHeader';

export default function ProfileIndex(){
    var base = new Base()

    const [user_data, set_user_data] = useState({CFULLNAME : '', CIDUSER : ''})
    const [def_input_arr, set_def_input_arr] = useState([
        {title : 'Camera Input', value : 'camera_input', is_selected : false},
        {title : 'Manual Input', value : 'manual_input', is_selected : false},
    ])
    const [input_selected, set_input_selected] = useState('')

    useEffect(async ()=>{
        // await localStorage.clear()
        var token = await localStorage.getItem('token')
        if(token == null){
            window.location.href = '/auth/login'
        }
        else {
            if(navigator.onLine){
                get_data()
            }
            else{
                base.$('#error_modal').modal('show')
            }
        }
    }, [])

    async function get_data(){
        base.$('#preloader_modal').modal('show')
        var url = '/auth/profile'
        var response = await base.request(url)
        if(response != null){
            if(response.status == 'success'){
                var data = response.data
                set_user_data(data)

                for(var x in def_input_arr){
                    if(def_input_arr[x].value === data.DEFAULT_INPUT){
                        def_input_arr[x].is_selected = true
                        base.update_array(def_input_arr, set_def_input_arr, def_input_arr[x], x)
                        set_input_selected(def_input_arr[x].value)
                        break
                    }
                }


                base.hidePreloader()
            }
            else{
                base.hidePreloader()
                base.$('#error_modal').modal('show')
            }
        }
        else{
            base.hidePreloader()
            base.$('#error_modal').modal('show')
        }
    }

    async function logoutBtn(){
        await localStorage.clear()
        window.location.href = '/auth/login'
    }

    async function changeImage(value){

    }

    async function editDefaultInput(e){
        e.preventDefault()
        base.$('#modalDefaultInput').modal('show')
    }

    async function changeInput(index){
        var data_index = def_input_arr[index]
        var initSelected = data_index.is_selected
        for(var x in def_input_arr){
            def_input_arr[x].is_selected = false
        }

        def_input_arr[index].is_selected = !initSelected
        if(def_input_arr[index].is_selected){
            set_input_selected(def_input_arr[index].value)
        }

        base.update_array(def_input_arr, set_def_input_arr, data_index, index)
    }

    async function saveDefInput(){
        
        var url = '/auth/change-default-input'
        var response = await base.request(url, 'put', {default_input : input_selected})
        if(response != null){
            if(response.status == 'success'){
                window.location.reload()
            }
        }
    }

    return(
        <div className='page_wrap'>
            <div className='row'>

                <div className='col-12'>
                    <PageHeader title={'Profile'} style={{fontSize : '1.5rem', fontWeight : '600'}} />
                </div>

                <div className='col-12 mt-5'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col-auto position-relative'>
                                    <img src={base.img_no_profile} style={{height : '5rem', width : '5rem', borderRadius : '5rem'}} />

                                    <input type="file" name="photo" accept="image/*" id="file_input" className="d-none" onChange={(event)=>changeImage(event)} /> 
                                    <div className='position-absolute p-2 d-flex align-items-center' style={{right : '.5rem', bottom : 0, borderRadius : '1.75rem', height : '1.75rem', width : '1.75rem', backgroundColor : '#95B8E2', cursor : 'pointer'}} onClick={()=>base.$('#file_input').trigger('click')}>
                                        <i class="bi bi-pencil-fill text-white" style={{fontSize : '.75rem'}}></i>
                                    </div>
                                </div>
                                <div className='col d-flex align-items-center'>
                                    <p className='m-0 text-capitalize' style={{fontSize : '1.25rem'}}>{user_data.CFULLNAME}</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-12 mt-4'>
                            <div className='row'>
                                <div className='col-12 py-3' style={{borderBottom : '1px solid #DADDDF'}}>
                                    <p className='m-0 text-uppercase'>USER NAME</p>
                                    <p className='m-0'>@{user_data.CIDUSER}</p>
                                </div>
                                <div className='col-12 py-3' style={{borderBottom : '1px solid #DADDDF'}}>
                                    <a href='#!' className='text-primary text-decoration-none'>Ganti Kata Sandi</a>
                                </div>
                                <div className='col-12 py-3' style={{borderBottom : '1px solid #DADDDF'}}>
                                    <a href='#!' className='text-primary text-decoration-none' onClick={(e)=>editDefaultInput(e)}>Edit Default Input</a>
                                </div>
                                <div className='col-12 py-3' style={{borderBottom : '1px solid #DADDDF'}}>
                                    <p className='m-0'>System Version 1.0</p>
                                </div>
                                <div className='col-12 py-3'>
                                    <a href='#!' onClick={()=>logoutBtn()} className='text-danger text-decoration-none'>Sign Out</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="modal fade" id="modalDefaultInput" tabIndex="-1" aria-labelledby="modalDefaultInputLabel" aria-hidden="true">
                <div className="modal-dialog p-4 pt-5 mt-5">
                    <div className="modal-content border-0 shadow-sm" style={{borderRadius : '.75rem'}}>
                        <div className="modal-body">
                            <div className={'row'}>
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <h3>Defaul Input</h3>
                                        </div>
                                        <div className='col-12'>
                                            <div className='row'>
                                                {
                                                    def_input_arr.map((data, index)=>(
                                                        <div className='col-12 py-3' style={{borderBottom : '1px solid #DADDDF'}}>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" name="defInput" id={"defInput" + index} value={data.value} checked={data.is_selected ? true : false} onClick={()=>changeInput(index)} />
                                                                <label class="form-check-label" for={"defInput" + index}>{data.title}</label>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" onClick={()=>saveDefInput()}>Save</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}